import React from "react";
import { navigate } from "gatsby";

//CORE COMPONENTS
import {
  ThemeProvider,
  styled,
} from "../../../../../components-core/packages/components-core/dist/theme-provider/index";
import {
  Container,
  ContainerWrap,
  ContainerPadding,
  ContainerGutter,
  ContainerHorizontalAlignment, g5ky32cgq4ua4pkczif7y3fsrulncfqgx36uau3zse2l3zkl22ta
} from "../../../../../components-core/packages/components-core/dist/container/index";

import {
  Button,
  ButtonAppearance,
} from "../../../../../components-core/packages/components-core/dist/button";

import {
  Text,
  TextAppearance,
  TextAlignment,
  TextColor,
} from "../../../../../components-core/packages/components-core/dist/text";
import { Breakpoints } from "../../../../../components-core/packages/components-core/dist/helpers";
import { Image } from "../../../../../components-core/packages/components-core/dist/image/index";
import { FixedWidthContainer } from "../../../../../components-core/packages/components-core/dist/fixed-width-container/index";
import { BreakpointWrapper } from "../../../../../components-core/packages/components-core/dist/breakpoint-wrapper/index";
import { Link } from "../../../../../components-core/packages/components-core/dist/link/index";

import PriceTable from "../../inner-components/price-table/PriceTable";
import OptionalAccordion from "../../../optional-accordion/OptionalAccordion";
import PriceCard from "../../inner-components/price-card/PriceCard";
import caddy from "../../inner-components/horizontal-car-list/caddy.png";
import crafter from "../../inner-components/horizontal-car-list/crafter.png";
import amarok from "../../inner-components/horizontal-car-list/amarok.png";
import transporter from "../../inner-components/horizontal-car-list/transporter.png";
import california from "../../inner-components/horizontal-car-list/california.png";
import grandCalifornia from "../../inner-components/horizontal-car-list/grand-california.png";
import caddyActive from "../../inner-components/horizontal-car-list/caddy-active.png";
import transporterActive from "../../inner-components/horizontal-car-list/transporter-active.png";
import crafterActive from "../../inner-components/horizontal-car-list/crafter-active.png";
import amarokActive from "../../inner-components/horizontal-car-list/amarok-active.png";
import multiVan from "../../inner-components/horizontal-car-list/multivan.png";
import multiVanActive from "../../inner-components/horizontal-car-list/multivan-active.png";
import californiaActive from "../../inner-components/horizontal-car-list/california-active.png";
import grandCaliforniaActive from "../../inner-components/horizontal-car-list/grand-california-active.png";
import LayoutTwo from "../../../layouttwo";
import './tabs.css'
import axios from "axios";
import MobileAppDownloadSection from "../../../mobile-app-download-section/MobileAppDownloadSection";

const HorizontalScrollableContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  align-items: center;
`;

const CarContainer = styled.div`
  flex: 0 0 auto;
  cursor: pointer;
`;

const SelectedLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: #00437a;
  display: ${(props) => (props.isSelected ? "block" : "none")};
`;

const TopContainerPrice = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

class HorizontalCarList extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedIndex: 0,
      selectedYearIndex: 0,
      updatingPage: true,
      token: "",
      caddyStyleDSGPrice: [],
      caddyLifePrice: [],
      caddyLifeDSGPrice: [],
      caddyImpressionPrice: [],
      caddyImpressionDSGPrice: [],
      caddyCargoPSPrice: [],
      caddyCargoPSDSGPrice: [],
      caddyCargoMaxiPSPrice: [],
      caddyCargoMaxiPSDSGPrice: [],
      trCamlıStandartPrice: [],
      trCamlıUzunPrice: [],
      trPanelVanStandartPrice: [],
      trPanelVanUzun110Price: [],
      trPanelVanUzun150Price: [],
      trPikapUzunTekKabinPrice: [],
      trPikapUzunCiftKabinPrice: [],
      caraHighlineDSG: [],
      caraHighlineDSG4Motion: [],
      caraComfortlineUzun110: [],
      caraComfortlineUzun150: [],
      caraComfortlineUzun150DSG: [],
      // crafterServisLWB15: [],
      crafterServisELWB16: [],
      crafterServisELWB19: [],
      // crafterOkulLWB16: [],
      crafterOkulELWB16: [],
      crafterOkulELWB19: [],
      crafterPanelVanMWB: [],
      crafterPanelVanLWB: [],
      crafterPanelVanLWB177: [],
      transporterModelsPrice: [],
      crafterModelsPrice: [],
      caravelleModelsPrice: [],
      opsiyonelCaddyOne: "",
      opsiyonelCaddyTwo: "",
      opsiyonelCaddyThree: "",
      opsiyonelCaddyFour: "",

      californiaOcean20TDI: [],
      californiaOcean20TDI4Motion: [],
      grandCalifornia6004Motion: [],
      amarokLifeTDI205: [],
      amarokStyleTDI205: [],
      amarokStylePlusTDI205: [],
      amarokStyleTDI240: [],
      amarokPanAmericanoTDI240: [],
      amarokAventuraTDI240: [],
      multiVanTSI218: [],
      opsiyonelTransporterOne: "",
      opsiyonelTransporterTwo: "",
      opsiyonelTransporterThree: "",
      opsiyonelCrafterOne: "",
      opsiyonelCrafterTwo: "",
      opsiyonelCrafterThree: "",
      opsiyonelCrafterFour: "",
      opsiyonelCrafterFive: "",
      opsiyonelCrafterSix: "",
      opsiyonelCrafterSeven: "",
      opsiyonelCrafterEight: "",
      opsiyonelCrafterNine: "",

      opsiyonelMultiVanOne: "",
      opsiyonelMultiVanTwo: "",

      opsiyonelGrandCaliforniaOne: "",
      opsiyonelGrandCaliforniaTwo: "",
      opsiyonelCaliforniaOceanOne: "",
      opsiyonelCaliforniaOceanTwo: "",
      // opsiyonelAmarokOne: "",
      opsiyonelAmarokTwo: "",
      opsiyonelAmarokThree: "",
      opsiyonelCaravelle: "",
      caddyUrl:
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelAllPrice?TopModelName=caddy",
      transporterUrl:
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelAllPrice?TopModelName=transporter",
      crafterUrl:
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelAllPrice?TopModelName=crafter",
      caravelleUrl:
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelAllPrice?TopModelName=caravelle",
      californiaUrl:
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelAllPrice?topModelName=california%20ocean",
      grandCaliforniaUrl:
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelAllPrice?topModelName=grand%20california",
      amarokUrl:
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelAllPrice?topModelName=Amarok",
      multiVanUrl:
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelAllPrice?topModelName=multivan",
      caddyTextResultOne: "",
      caddyTextResultTwo: "",
      caddyTextResultThree: "",
      caddyTextResultFour: "",
      caddyTextResultFive: "",
      caddyTextResultSix: "",
      caddyTextResultSeven: "",
      caddyTextResultEight: "",
      caddyTextResultNine: "",
      caddyTextResultTen: "",

      amarokTextResultOne: "",
      amarokTextResultTwo: "",
      amarokTextResultThree: "",
      amarokTextResultFour: "",
      amarokTextResultFive: "",
      amarokTextResultSix: "",
      amarokTextResultSeven: "",
      amarokTextResultEight: "",
      amarokTextResultNine: "",
      amarokTextResultTen: "",

      transporterTextOne: "",
      transporterTextTwo: "",
      transporterTextThree: "",
      transporterTextFour: "",
      transporterTextFive: "",
      transporterTextSix: "",
      transporterTextSeven: "",
      transporterTextEight: "",
      transporterTextNine: "",
      transporterTextTen: "",
      transporterTextEleven: "",

      caravelleTextOne: "",
      caravelleTextTwo: "",
      caravelleTextThree: "",
      caravelleTextFour: "",
      caravelleTextFive: "",
      caravelleTextSix: "",
      caravelleTextSeven: "",
      caravelleTextEight: "",
      caravelleTextNine: "",
      caravelleTextTen: "",

      crafterTextOne: "",
      crafterTextTwo: "",
      crafterTextThree: "",
      crafterTextFour: "",
      crafterTextFive: "",
      crafterTextSix: "",
      crafterTextSeven: "",
      crafterTextEight: "",
      crafterTextNine: "",
      crafterTextTen: "",

      multiVanTextTwo: "",
      multiVanTextOne: "",
      multiVanTextThree: "",
      multiVanTextFour: "",
      multiVanTextFive: "",
      multiVanTextSix: "",
      multiVanTextSeven: "",
      multiVanTextEight: "",
      multiVanTextNine: "",
      multiVanTextTen: "",

      californiaTextOne: "",
      californiaTextTwo: "",
      californiaTextThree: "",
      californiaTextFour: "",
      californiaTextFive: "",
      californiaTextSix: "",
      californiaTextSeven: "",
      californiaTextEight: "",
      californiaTextNine: "",
      californiaTextTen: "",

      grandCaliforniaTextOne: "",
      grandCaliforniaTextTwo: "",
      grandCaliforniaTextThree: "",
      grandCaliforniaTextFour: "",
      grandCaliforniaTextFive: "",
      grandCaliforniaTextSix: "",
      grandCaliforniaTextSeven: "",
      grandCaliforniaTextEight: "",
      grandCaliforniaTextNine: "",
    };
  }
  async componentDidMount() {
    const tokenurl =
      "https://ticariaracwebapi.vw.com.tr/api/Authorization/RequestToken";
    const authObject = {
      auth: {
        username: "VwTicariWebApi",
        password: "^UzpqTUoqC5@JR@2Dk&y",
      },
    };

    await axios
      .post(
        tokenurl,
        {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        },
        authObject
      )
      .then((response) => {
        this.state.token = "Bearer ".concat(response.data.token);
      })
      .catch((error) => { });

    await axios
      .get(
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetPriceListVisible",
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({ updatingPage: response.data.result[0].isVisiblePanel });
      })
      .catch((error) => { });

    await axios
      .get(this.state.caddyUrl, {
        headers: {
          Authorization: this.state.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        this.setState({ caddyStyleDSGPrice: response.data.result[3] });
        this.setState({ caddyLifePrice: response.data.result[1] });
        this.setState({ caddyLifeDSGPrice: response.data.result[2] });
        // this.setState({ caddyImpressionPrice: response.data.result[0] });
        this.setState({ caddyImpressionDSGPrice: response.data.result[0] });
        // this.setState({ caddyCargoPSPrice: response.data.result[5] });
        this.setState({ caddyCargoPSDSGPrice: response.data.result[4] });
        // this.setState({ caddyCargoMaxiPSPrice: response.data.result[7] });
        this.setState({ caddyCargoMaxiPSDSGPrice: response.data.result[5] });
      })
      .catch((error) => { });
    await axios
      .get(this.state.transporterUrl, {
        headers: {
          Authorization: this.state.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        this.setState({ trCamlıStandartPrice: response.data.result[0] });
        this.setState({ trCamlıUzunPrice: response.data.result[1] });
        this.setState({ trPanelVanStandartPrice: response.data.result[6] });
        this.setState({ trPanelVanUzun110Price: response.data.result[2] });
        this.setState({ trPanelVanUzun150Price: response.data.result[3] });
        this.setState({ trPikapUzunTekKabinPrice: response.data.result[4] });
        this.setState({ trPikapUzunCiftKabinPrice: response.data.result[5] });
      })
      .catch((error) => { });
    await axios
      .get(this.state.crafterUrl, {
        headers: {
          Authorization: this.state.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        this.setState({ crafterPanelVanMWB: response.data.result[0] });
        this.setState({ crafterPanelVanLWB: response.data.result[1] });
        this.setState({ crafterServisELWB16: response.data.result[2] });
        this.setState({ crafterServisELWB19: response.data.result[3] });
        this.setState({ crafterOkulELWB16: response.data.result[4] });
        this.setState({ crafterOkulELWB19: response.data.result[5] });
        this.setState({ crafterPanelVanLWB177: response.data.result[6] });
      })
      .catch((error) => { });
    await axios
      .get(this.state.multiVanUrl, {
        headers: {
          Authorization: this.state.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        this.setState({ multiVanTSI218: response.data.result[0] });
      })
      .catch((error) => { });
    await axios
      .get(this.state.caravelleUrl, {
        headers: {
          Authorization: this.state.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        this.setState({ caraHighlineDSG: response.data.result[1] });
        this.setState({ caraHighlineDSG4Motion: response.data.result[2] });
        // this.setState({ caraComfortlineUzun110: response.data.result[4] });
        // this.setState({ caraComfortlineUzun150: response.data.result[0] });
        this.setState({ caraComfortlineUzun150DSG: response.data.result[0] });
      })
      .catch((error) => { });

    await axios
      .get(this.state.californiaUrl, {
        headers: {
          Authorization: this.state.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        this.setState({ californiaOcean20TDI: response.data.result[0] });
        this.setState({ californiaOcean20TDI4Motion: response.data.result[1] });
      })
      .catch((error) => { });

    await axios
      .get(this.state.grandCaliforniaUrl, {
        headers: {
          Authorization: this.state.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        this.setState({ grandCalifornia6004Motion: response.data.result[0] });
      })
      .catch((error) => { });

    await axios
      .get(this.state.amarokUrl, {
        headers: {
          Authorization: this.state.token,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        this.setState({ amarokLifeTDI205: response.data.result[0] });
        this.setState({ amarokStyleTDI205: response.data.result[1] });
        this.setState({ amarokStylePlusTDI205: response.data.result[2] });
        this.setState({ amarokStyleTDI240: response.data.result[3] });
        this.setState({ amarokPanAmericanoTDI240: response.data.result[4] });
        this.setState({ amarokAventuraTDI240: response.data.result[5] });
      })
      .catch((error) => { });
    await axios
      .get(
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelOption?CategoryId=1",
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({ opsiyonelCaddyOne: response.data.result[0].price });
        this.setState({ opsiyonelCaddyTwo: response.data.result[1].price });
        this.setState({ opsiyonelCaddyThree: response.data.result[2].price });
        this.setState({ opsiyonelCaddyFour: response.data.result[3].price });

      })
      .catch((error) => { });

    // await axios
    //   .get(
    //     "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelOption?CategoryId=4",
    //     {
    //       headers: {
    //         Authorization: this.state.token,
    //         "Access-Control-Allow-Origin": "*",
    //         "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
    //         "X-Requested-With": "XMLHttpRequest",
    //         "Content-type": "application/json; charset=utf-8",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     this.setState({ opsiyonelAmarokOne: response.data.result[0].price });
    //     this.setState({ opsiyonelAmarokTwo: response.data.result[1].price });
    //     this.setState({ opsiyonelAmarokThree: response.data.result[2].price });
    //   })
    //   .catch((error) => { });

    // transporter
    await axios
      .get(
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelOption?CategoryId=2",
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({
          opsiyonelTransporterOne: response.data.result[0].price,
        });
        this.setState({
          opsiyonelTransporterTwo: response.data.result[1].price,
        });
        // this.setState({
        //   opsiyonelTransporterThree: response.data.result[1].price,
        // });
      })
      .catch((error) => { });

    //
    await axios
      .get(
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelOption?CategoryId=8",
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({
          opsiyonelCaliforniaOceanOne: response.data.result[0].price,
        });
        this.setState({
          opsiyonelCaliforniaOceanTwo: response.data.result[1].price,
        });
      })
      .catch((error) => { });

    await axios
      .get(
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelOption?CategoryId=9",
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({
          opsiyonelGrandCaliforniaOne: response.data.result[0].price,
        });
        this.setState({
          opsiyonelGrandCaliforniaTwo: response.data.result[1].price,
        });
      })
      .catch((error) => { });

    await axios
      .get(
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelOption?CategoryId=5",
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({ opsiyonelCrafterOne: response.data.result[0].price });
        this.setState({ opsiyonelCrafterTwo: response.data.result[1].price });
        this.setState({ opsiyonelCrafterThree: response.data.result[8].price });
        this.setState({ opsiyonelCrafterFour: response.data.result[2].price });
        this.setState({ opsiyonelCrafterFive: response.data.result[3].price });
        this.setState({ opsiyonelCrafterSix: response.data.result[4].price });
        this.setState({ opsiyonelCrafterSeven: response.data.result[5].price });
        this.setState({ opsiyonelCrafterEight: response.data.result[6].price });
        this.setState({ opsiyonelCrafterNine: response.data.result[7].price });
      })
      .catch((error) => { });

    await axios
      .get(
        "https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelOption?CategoryId=10",
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({ opsiyonelMultiVanOne: response.data.result[0].price });
        this.setState({ opsiyonelMultiVanTwo: response.data.result[1].price });
      })
      .catch((error) => { });


    await axios
      .get(
        `https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelPriceListDescription?CategoryId=6`,
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({
          caddyTextResultOne: response.data.result[0].description,
        });
        this.setState({
          caddyTextResultTwo: response.data.result[1].description,
        });
        this.setState({
          caddyTextResultThree: response.data.result[2].description,
        });
        this.setState({
          caddyTextResultFour: response.data.result[3].description,
        });
        this.setState({
          caddyTextResultFive: response.data.result[4].description,
        });
        this.setState({
          caddyTextResultSix: response.data.result[5].description,
        });
        this.setState({
          caddyTextResultSeven: response.data.result[6].description,
        });
        this.setState({
          caddyTextResultEight: response.data.result[7].description,
        });
        this.setState({
          caddyTextResultNine: response.data.result[8].description,
        });
        this.setState({
          caddyTextResultTen: response.data.result[9].description,
        });
      })
      .catch((error) => { });

    await axios
      .get(
        `https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelPriceListDescription?CategoryId=4`,
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({
          amarokTextResultOne: response.data.result[0].description,
        });
        this.setState({
          amarokTextResultTwo: response.data.result[1].description,
        });
        this.setState({
          amarokTextResultThree: response.data.result[2].description,
        });
        this.setState({
          amarokTextResultFour: response.data.result[3].description,
        });
        this.setState({
          amarokTextResultFive: response.data.result[4].description,
        });
        this.setState({
          amarokTextResultSix: response.data.result[5].description,
        });
        this.setState({
          amarokTextResultSeven: response.data.result[6].description,
        });
        this.setState({
          amarokTextResultEight: response.data.result[7].description,
        });
        this.setState({
          amarokTextResultNine: response.data.result[8].description,
        });
      })
      .catch((error) => { });

    await axios
      .get(
        `https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelPriceListDescription?CategoryId=2`,
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({
          transporterTextOne: response.data.result[0].description,
        });
        this.setState({
          transporterTextTwo: response.data.result[1].description,
        });
        this.setState({
          transporterTextThree: response.data.result[2].description,
        });
        this.setState({
          transporterTextFour: response.data.result[3].description,
        });
        this.setState({
          transporterTextFive: response.data.result[4].description,
        });
        this.setState({
          transporterTextSix: response.data.result[5].description,
        });
        this.setState({
          transporterTextSeven: response.data.result[6].description,
        });
        this.setState({
          transporterTextEight: response.data.result[7].description,
        });
        this.setState({
          transporterTextNine: response.data.result[8].description,
        });
        this.setState({
          transporterTextTen: response.data.result[9].description,
        });
        this.setState({
          transporterTextEleven: response.data.result[10].description,
        });
      })
      .catch((error) => { });
    await axios
      .get(
        `https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelPriceListDescription?CategoryId=3`,
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({
          caravelleTextOne: response.data.result[0].description,
        });
        this.setState({
          caravelleTextTwo: response.data.result[1].description,
        });
        this.setState({
          caravelleTextThree: response.data.result[2].description,
        });
        this.setState({
          caravelleTextFour: response.data.result[3].description,
        });
        this.setState({
          caravelleTextFive: response.data.result[4].description,
        });
        this.setState({
          caravelleTextSix: response.data.result[5].description,
        });
        this.setState({
          caravelleTextSeven: response.data.result[6].description,
        });
        this.setState({
          caravelleTextEight: response.data.result[7].description,
        });
        this.setState({
          caravelleTextNine: response.data.result[8].description,
        });
        this.setState({
          caravelleTextTen: response.data.result[9].description,
        });
      })
      .catch((error) => { });
    await axios
      .get(
        `https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelPriceListDescription?CategoryId=5`,
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({ crafterTextOne: response.data.result[0].description });
        this.setState({ crafterTextTwo: response.data.result[1].description });
        this.setState({
          crafterTextThree: response.data.result[2].description,
        });
        this.setState({ crafterTextFour: response.data.result[3].description });
        this.setState({ crafterTextFive: response.data.result[4].description });
        this.setState({ crafterTextSix: response.data.result[5].description });
        this.setState({
          crafterTextSeven: response.data.result[6].description,
        });
        this.setState({
          crafterTextEight: response.data.result[7].description,
        });
        this.setState({ crafterTextNine: response.data.result[8].description });
        this.setState({ crafterTextTen: response.data.result[9].description });
      })
      .catch((error) => { });

    await axios
      .get(
        `https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelPriceListDescription?CategoryId=10`,
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({ multiVanTextOne: response.data.result[0].description });
        this.setState({ multiVanTextTwo: response.data.result[1].description });
        this.setState({
          multiVanTextThree: response.data.result[2].description,
        });
        this.setState({ multiVanTextFour: response.data.result[3].description });
        this.setState({ multiVanTextFive: response.data.result[4].description });
        this.setState({ multiVanTextSix: response.data.result[5].description });
        this.setState({
          multiVanTextSeven: response.data.result[6].description,
        });
        this.setState({
          multiVanTextEight: response.data.result[7].description,
        });
        this.setState({ multiVanTextNine: response.data.result[8].description });
        this.setState({ multiVanTextTen: response.data.result[9].description });
      })
      .catch((error) => { });

    await axios
      .get(
        `https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelPriceListDescription?categoryId=8`,
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({ californiaTextOne: response.data.result[0].description });
        this.setState({ californiaTextTwo: response.data.result[1].description });
        this.setState({
          californiaTextThree: response.data.result[2].description,
        });
        this.setState({ californiaTextFour: response.data.result[3].description });
        this.setState({ californiaTextFive: response.data.result[4].description });
        this.setState({ californiaTextSix: response.data.result[5].description });
        this.setState({
          californiaTextSeven: response.data.result[6].description,
        });
        this.setState({
          californiaTextEight: response.data.result[7].description,
        });
        this.setState({
          californiaTextNine: response.data.result[8].description,
        });
        this.setState({
          californiaTextTen: response.data.result[9].description,
        });
      })
      .catch((error) => { });

    await axios
      .get(
        `https://ticariaracwebapi.vw.com.tr/api/PriceList/GetModelPriceListDescription?categoryId=9`,
        {
          headers: {
            Authorization: this.state.token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
            "X-Requested-With": "XMLHttpRequest",
            "Content-type": "application/json; charset=utf-8",
          },
        }
      )
      .then((response) => {
        this.setState({ grandCaliforniaTextOne: response.data.result[0].description });
        this.setState({ grandCaliforniaTextTwo: response.data.result[1].description });
        this.setState({
          grandCaliforniaTextThree: response.data.result[2].description,
        });
        this.setState({ grandCaliforniaTextFour: response.data.result[3].description });
        this.setState({ grandCaliforniaTextFive: response.data.result[4].description });
        this.setState({ grandCaliforniaTextSix: response.data.result[5].description });
        this.setState({
          grandCaliforniaTextSeven: response.data.result[6].description,
        });
        this.setState({
          grandCaliforniaTextEight: response.data.result[7].description,
        });
        this.setState({
          grandCaliforniaTextNine: response.data.result[8].description,
        });
      })
      .catch((error) => { });
  }



  renderTitle() {
    const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;
    if (isMobile) {
      return (
        <Container
          padding={padding4Dimensions}
          horizontalAlign={ContainerHorizontalAlignment.center}
        >
          <h2>
            <Text
              textAlign={TextAlignment.center}
              appearance={TextAppearance.headline0400}
            >
              Fiyatlarımızı&nbsp;
              <br />
              <Text bold appearance={TextAppearance.headline0400}>
                güncelliyoruz
              </Text>
            </Text>
          </h2>
        </Container>
      );
    } else {
      return (
        <Container
          padding={padding4Dimensions}
          horizontalAlign={ContainerHorizontalAlignment.center}
        >
          <h2>
            <Text
              textAlign={TextAlignment.center}
              appearance={TextAppearance.headline0400}
            >
              Fiyatlarımızı&nbsp;
              <Text bold appearance={TextAppearance.headline0400}>
                güncelliyoruz
              </Text>
            </Text>
          </h2>
        </Container>
      );
    }
  }

  renderDescription() {
    return (
      <Container
        padding={padding4Dimensions}
        horizontalAlign={ContainerHorizontalAlignment.center}
      >
        <Text
          textAlign={TextAlignment.center}
          appearance={TextAppearance.copy0200}
        >
          Bu sırada modellerimizi inceleyebilirsiniz.
        </Text>
      </Container>
    );
  }

  renderButton() {
    return (
      <Container
        horizontalAlign={ContainerHorizontalAlignment.center}
        padding={{ left: globalPadding, right: globalPadding }}
      >
        <Button
          appearance={ButtonAppearance.Primary}
          onClick={() => navigate("https://ticariarac.vw.com.tr/modeller/")}
        >
          <Text appearance={TextAppearance.copy0150} color={TextColor.inherit}>
            Modelleri İncele
          </Text>
        </Button>
      </Container>
    );
  }

  renderInnerContainer() {
    const mainContainerPadding = {
      top: ContainerPadding.dynamic0300,
      bottom: ContainerPadding.dynamic0300,
    };

    return (
      <Container
        padding={mainContainerPadding}
        horizontalAlign={ContainerHorizontalAlignment.center}
      >
        <Container
          horizontalAlign={ContainerHorizontalAlignment.center}
          stretchContent={true}
          wrap={ContainerWrap.always}
        >
          {this.renderTitle()}
          {this.renderDescription()}
          {this.renderButton()}
        </Container>
      </Container>
    );
  }

  renderCars() {
    const datasetLength = dummyArray.length;
    const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;

    if (isMobile) {
      return dummyArray.map((item, index) => (
        <CarContainer onClick={() => this.setState({ selectedIndex: index })}>
          <Container padding={carContainerPadding(index, datasetLength)}>
            <FixedWidthContainer columns={fixedWidthContainerAppearance}>
              <Container
                wrap={ContainerWrap.always}
                gutter={ContainerGutter.static200}
              >
                {index === this.state.selectedIndex ? (
                  <Image
                    width="64px"
                    height="30px"
                    src={item.activeImage}
                    alt={item.img}
                  />
                ) : (
                  <Image width={'100%'} height={'100%'} src={item.img} alt={item.img} />
                )}
                <Container stretchContent={true} wrap={ContainerWrap.always}>
                  {item.name === "Transporter" ? (
                    <Text appearance={TextAppearance.copy0100} bold={true}>
                      <Link>{item.name}</Link>
                    </Text>
                  ) : (
                    <Text
                      appearance={TextAppearance.copy0100}
                      textAlign={TextAlignment.center}
                      bold={true}
                    >
                      <Link>{item.name}</Link>
                    </Text>
                  )}
                  <SelectedLine
                    isSelected={this.state.selectedIndex === index}
                  />
                </Container>
              </Container>
            </FixedWidthContainer>
          </Container>
        </CarContainer>
      ));
    } else {
      return dummyArray.map((item, index) => (
        <CarContainer onClick={() => this.setState({ selectedIndex: index })}>
          <Container padding={carContainerPadding(index, datasetLength)}>
            <FixedWidthContainer columns={fixedWidthContainerAppearance}>
              <Container
                wrap={ContainerWrap.always}
                gutter={ContainerGutter.static200}
              >
                {index === this.state.selectedIndex ? (
                  <Image src={item.activeImage} alt={item.img} />
                ) : (
                  <Image src={item.img} alt={item.img} />
                )}
                <Container stretchContent={true} wrap={ContainerWrap.always}>
                  <Text
                    appearance={TextAppearance.copy0150}
                    textAlign={TextAlignment.center}
                    bold={true}
                  >
                    <Link>{item.name}</Link>
                  </Text>
                  <SelectedLine
                    isSelected={this.state.selectedIndex === index}
                  />
                </Container>
              </Container>
            </FixedWidthContainer>
          </Container>
        </CarContainer>
      ));
    }
  }

  render() {
    // if (this.state.updatingPage && (this.state.selectedIndex == 0 || this.state.selectedIndex == 1 || this.state.selectedIndex == 4 || this.state.selectedIndex == 6 )) {
        if (this.state.updatingPage && (this.state.selectedIndex == 0 || this.state.selectedIndex == 1 || this.state.selectedIndex == 2 || this.state.selectedIndex == 3 || this.state.selectedIndex == 5)) {

    return (
        <LayoutTwo theme={"main"}>
          <div style={{ marginTop: "100px" }}>
            <Container
              horizontalAlign={ContainerHorizontalAlignment.center}
              padding={ContainerPadding.static200}
            >
              <div style={{ display: "flex", flexDirection:'column' }}>
                <ThemeProvider theme={"main"}>
                  <Container wrap={ContainerWrap.never} stretchContent={true}>
                    <BreakpointWrapper max={Breakpoints.b960}>
                      <HorizontalScrollableContainer>
                        {this.renderCars()}
                      </HorizontalScrollableContainer>
                    </BreakpointWrapper>
                    <BreakpointWrapper min={Breakpoints.b960}>
                      <Container
                        horizontalAlign={ContainerHorizontalAlignment.center}
                      >
                        {this.renderCars()}
                      </Container>
                    </BreakpointWrapper>
                  </Container>
                </ThemeProvider>
                {/* {this.renderPriceList(this.state.selectedIndex, 1)} */}
                <BreakpointWrapper max={Breakpoints.b960}>
                  <AmarokTabs>
                    <Tab label="2024 Yılı">
                      {this.renderPriceList(this.state.selectedIndex, 1)}
                    </Tab>
                    {/* <Tab label="2023 Yılı">
                    {this.renderPriceList(this.state.selectedIndex, 0)}
                  </Tab> */}
                  </AmarokTabs>
                  {/* {this.renderPriceList(this.state.selectedIndex)} */}
                </BreakpointWrapper>
                <BreakpointWrapper min={Breakpoints.b960}>
                  <AmarokTabs>
                    <Tab label="2024 Yılı">
                      {this.renderPriceList(this.state.selectedIndex, 1)}
                    </Tab>
                    {/* <Tab label="2023 Yılı">
                    {this.renderPriceList(this.state.selectedIndex, 0)}
                  </Tab> */}
                  </AmarokTabs>
                  {/* {this.renderPriceList(this.state.selectedIndex)} */}
                </BreakpointWrapper>
              </div>
            </Container>
          </div>

        </LayoutTwo>
      );
    }
    // else if (this.state.updatingPage && this.state.selectedIndex == 5) {
      if (this.state.updatingPage && !(this.state.selectedIndex == 0 || this.state.selectedIndex == 1 || this.state.selectedIndex == 2 || this.state.selectedIndex == 3 || this.state.selectedIndex == 5)) {
        return (
        <LayoutTwo theme={"main"}>
          <div style={{ marginTop: "100px" }}>
            <Container
              horizontalAlign={ContainerHorizontalAlignment.center}
              padding={ContainerPadding.static200}
            >
              <ThemeProvider theme={"main"}>
                <Container wrap={ContainerWrap.never} stretchContent={true}>
                  <BreakpointWrapper max={Breakpoints.b960}>
                    <HorizontalScrollableContainer>
                      {this.renderCars()}
                    </HorizontalScrollableContainer>
                  </BreakpointWrapper>
                  <BreakpointWrapper min={Breakpoints.b960}>
                    <Container
                      horizontalAlign={ContainerHorizontalAlignment.center}
                    >
                      {this.renderCars()}
                    </Container>
                  </BreakpointWrapper>
                </Container>
              </ThemeProvider>
              {/* {this.renderPriceList(this.state.selectedIndex, 1)} */}
              <BreakpointWrapper max={Breakpoints.b960}>
                <Tabs>
                  <Tab label="2024 Yılı">
                    {this.renderPriceList(this.state.selectedIndex, 1)}
                  </Tab>
                  <Tab label="2023 Yılı">
                    {this.renderPriceList(this.state.selectedIndex, 0)}
                  </Tab>
                </Tabs>
                {/* {this.renderPriceList(this.state.selectedIndex)} */}
              </BreakpointWrapper>
              <BreakpointWrapper min={Breakpoints.b960}>
                <Tabs>
                  <Tab label="2024 Yılı">
                    {this.renderPriceList(this.state.selectedIndex, 1)}
                  </Tab>
                  <Tab label="2023 Yılı">
                    {this.renderPriceList(this.state.selectedIndex, 0)}
                  </Tab>
                </Tabs>
                {/* {this.renderPriceList(this.state.selectedIndex)} */}
              </BreakpointWrapper>
            </Container>
          </div>
        </LayoutTwo>
      );
    }
    else {
      return (
        <LayoutTwo theme={"main"}>
          <div style={{ marginTop: "100px" }}>
            <Container
              horizontalAlign={ContainerHorizontalAlignment.center}
              padding={ContainerPadding.static200}
            >
              <ThemeProvider theme={"main"}>
                <Container wrap={ContainerWrap.never} stretchContent={true}>
                  <TopContainerPrice>
                    {this.renderInnerContainer()}
                  </TopContainerPrice>
                </Container>
              </ThemeProvider>
            </Container>
          </div>
        </LayoutTwo>
      );
    }
  }

  renderPriceText() {
    return (
      <Container
        horizontalAlign={ContainerHorizontalAlignment.center}
        padding={ContainerPadding.static200}
      >
        <Text
          appearance={TextAppearance.headline350}
          textAlign={TextAlignment.center}
        >
          Fiyat Listeleri
        </Text>
      </Container>
    );
  }

  renderPriceList(selectedIndex, selectedYearIndex) {
    const {
      custom,
      custom1,
      custom2,
      custom3,
      custom4,
      custom5,
      custom6,
      custom7,
      custom8,
      custom9,
      custom10,
      custom11,
      custom12,
      custom13,
      custom14,
      custom15,
      custom16,
      custom18
    } = this.props;
    const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;

    const caddyOptional = {
      title: "Caddy Opsiyonlar",
      price: "0000",
      description: "Kampanyalı anahtar teslim fiyatı",
      caddyOptionalDetails: [
        {
          title: "Metalik Renk",
          description: this.state.opsiyonelCaddyOne,
        },
        {
          title: "Panoramik Cam Tavan",
          description: this.state.opsiyonelCaddyTwo,
        }
      ],
    };

    const oldCaddyOptional = {
      title: "Caddy Opsiyonlar",
      price: "0000",
      description: "Kampanyalı anahtar teslim fiyatı",
      caddyOptionalDetails: [
        {
          title: "Metalik Renk",
          description: '20.000 TL',
        },
        {
          title: "Panoramik Cam Tavan",
          description: '27.500 TL',
        },
        // {
        //   title: '10" Discover Pro Navigasyon sistemi',
        //   description: this.state.opsiyonelCaddyThree,
        // },
        // {
        //   title: "Yardımcı aydınlatma sistemine sahip dinamik LED ön farlar ve dönüş farları*",
        //   description: this.state.opsiyonelCaddyFour,
        // },
      ],
    };

    const transporterOptional = {
      title: "Transporter Opsiyonlar",
      price: "0000",
      description: "Kampanyalı anahtar teslim fiyatı",
      transporterOptionalDetails: [
        {
          title: "Metalik Renk",
          description: this.state.opsiyonelTransporterOne,
        },
        // {
        //   title: "Otomatik Vites Standart Şasi*",
        //   description: this.state.opsiyonelTransporterThree,
        // },
        {
          title: "Otomatik Vites*",
          description: this.state.opsiyonelTransporterTwo,
        },
        // {
        //   title: "+1 yıl Garanti Plus (City Van / Camlı Van)",
        //   description: "11.078 TL",
        // },
      ],
    };

    const californiaOceanOpsiyonel = {
      title: "California Ocean Opsiyonlar",
      price: "0000",
      description: "Kampanyalı anahtar teslim fiyatı",
      californiaOceanOpsiyonelDetails: [
        {
          title: "Metalik Renk",
          description: this.state.opsiyonelCaliforniaOceanOne,
        },
        // {
        //   title: "Otomatik Vites Standart Şasi*",
        //   description: this.state.opsiyonelTransporterThree,
        // },
        {
          title: "Çift Renk*",
          description: this.state.opsiyonelCaliforniaOceanTwo,
        },
        // {
        //   title: "+1 yıl Garanti Plus (City Van / Camlı Van)",
        //   description: "11.078 TL",
        // },
      ],
    };

    const grandCaliforniaOpsiyonel = {
      title: "Grand California Ocean Opsiyonlar",
      price: "0000",
      description: "Kampanyalı anahtar teslim fiyatı",
      grandCaliforniaOpsiyonelDetails: [
        {
          title: "Metalik Renk",
          description: this.state.opsiyonelGrandCaliforniaOne,
        },
        {
          title: "Çift Renk*",
          description: this.state.opsiyonelGrandCaliforniaTwo,
        },
      ],
    };

    const oldTransporterOptional = {
      title: "Transporter Opsiyonlar",
      price: "0000",
      description: "Kampanyalı anahtar teslim fiyatı",
      transporterOptionalDetails: [
        {
          title: "Metalik Renk",
          description: '27.500 TL',
        },
        {
          title: "Otomatik Vites*",
          description: '95.000 TL',
        },
      ],
    };

    const caravelleOptional = {
      title: "Caravelle Opsiyonlar",
      price: "0000",
      description: "Kampanyalı anahtar teslim fiyatı",
      caravelleOptionalDetails: [
        // {
        //   title: "+1 yıl Garanti Plus (Comfortline 150 PS)",
        //   description: "11.078 TL",
        // },
      ],
    };

    const multiVanOptional = {
      title: "Multi Van Opsiyonlar",
      price: "0000",
      description: "Kampanyalı anahtar teslim fiyatı",
      multiVanOptionalDetails: [
        {
          title: "Metalik Renk",
          description: this.state.opsiyonelMultiVanOne,
        },
        {
          title: "Çift Renk",
          description: this.state.opsiyonelMultiVanTwo,
        },
      ]
    }

    const crafterOptional = {
      title: "Crafter Opsiyonlar",
      price: "0000",
      description: "Kampanyalı anahtar teslim fiyatı",
      crafterOptionalDetails: [
        {
          title: "Metalik Renk",
          description: this.state.opsiyonelCrafterOne,
        },
        {
          title: "Otomatik Şanzıman",
          description: this.state.opsiyonelCrafterTwo,
        },
        // {
        //   title: "177 PS motor (Panelvan)",
        //   description: this.state.opsiyonelCrafterThree,
        // },
        // {
        //   title: "4Motion(Panelvan)",
        //   description: this.state.opsiyonelCrafterFour,
        // },
        // {
        //   title: "Klima (Panelvan)",
        //   description: this.state.opsiyonelCrafterFive,
        // },
        // {
        //   title: "Led Farlar(Panelvan)",
        //   description: this.state.opsiyonelCrafterSix,
        // },
        // {
        //   title: "Şerit Takip Sistemi",
        //   description: this.state.opsiyonelCrafterSeven,
        // },
        // {
        //   title: "OYA 3 Noktadan Gergili Emniyet Kemeri (16+1 Servis)",
        //   description: this.state.opsiyonelCrafterEight,
        // },
        // {
        //   title: "OYA 3 Noktadan Gergili Emniyet Kemeri (19+1 Servis)",
        //   description: this.state.opsiyonelCrafterNine,
        // },
      ],
    };

    const oldCrafterOptional = {
      title: "Crafter Opsiyonlar",
      price: "0000",
      description: "Kampanyalı anahtar teslim fiyatı",
      crafterOptionalDetails: [
        {
          title: "Metalik Renk",
          description: '50.000 TL',
        },
        {
          title: "Otomatik Vites",
          description: '95.000 TL',
        },
      ],
    };

    const caddyModels = {
      title: "Caddy Modeller",
      description: "Anahtar ve Kampanyalı teslim fiyatları",
      caddyImpressionDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caddyImpressionPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caddyImpressionPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caddyImpressionPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caddyImpressionPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.caddyImpressionPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.caddyImpressionPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caddyImpressionPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caddyImpressionPrice.ttiHizmet,
        },
      ],
      multiVaneHybrid218TSIDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.multiVanTSI218.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caddyImpressionDSGPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.multiVanTSI218.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.multiVanTSI218.otv,
        },
        {
          title: "KDV",
          description: this.state.multiVanTSI218.kdv,
        },
        {
          title: "MTV",
          description: this.state.multiVanTSI218.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.multiVanTSI218.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.multiVanTSI218.ttiHizmet,
        },
      ],
      caddyImptessionDSGDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caddyImpressionDSGPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caddyImpressionDSGPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caddyImpressionDSGPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caddyImpressionDSGPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.caddyImpressionDSGPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.caddyImpressionDSGPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caddyImpressionDSGPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caddyImpressionDSGPrice.ttiHizmet,
        },
      ],
      caddyLifeDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caddyLifePrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caddyLifePrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caddyLifePrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caddyLifePrice.otv,
        },
        {
          title: "KDV",
          description: this.state.caddyLifePrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.caddyLifePrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caddyLifePrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caddyLifePrice.ttiHizmet,
        },
      ],
      caddyLifeDSGDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caddyLifeDSGPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caddyLifeDSGPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caddyLifeDSGPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caddyLifeDSGPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.caddyLifeDSGPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.caddyLifeDSGPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caddyLifeDSGPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caddyLifeDSGPrice.ttiHizmet,
        },
      ],
      caddyStyleDSGDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caddyStyleDSGPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caddyStyleDSGPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caddyStyleDSGPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caddyStyleDSGPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.caddyStyleDSGPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.caddyStyleDSGPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caddyStyleDSGPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caddyStyleDSGPrice.ttiHizmet,
        },
      ],
      caddyCargo122PSDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caddyCargoPSPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caddyCargoPSPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caddyCargoPSPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caddyCargoPSPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.caddyCargoPSPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.caddyCargoPSPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caddyCargoPSPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caddyCargoPSPrice.ttiHizmet,
        },
      ],

      caddyCargo122PSDSGDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caddyCargoPSDSGPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caddyCargoPSDSGPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caddyCargoPSDSGPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caddyCargoPSDSGPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.caddyCargoPSDSGPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.caddyCargoPSDSGPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caddyCargoPSDSGPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caddyCargoPSDSGPrice.ttiHizmet,
        },
      ],
      caddyCargoMaxi122PSDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caddyCargoMaxiPSPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caddyCargoMaxiPSPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caddyCargoMaxiPSPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caddyCargoMaxiPSPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.caddyCargoMaxiPSPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.caddyCargoMaxiPSPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caddyCargoMaxiPSPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caddyCargoMaxiPSPrice.ttiHizmet,
        },
      ],
      caddyCargoMaxi122PSDSGDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caddyCargoMaxiPSDSGPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caddyCargoMaxiPSDSGPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caddyCargoMaxiPSDSGPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caddyCargoMaxiPSDSGPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.caddyCargoMaxiPSDSGPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.caddyCargoMaxiPSDSGPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caddyCargoMaxiPSDSGPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caddyCargoMaxiPSDSGPrice.ttiHizmet,
        },
      ],
      trCityVanStandartSasiModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.trCamlıStandartPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.trCamlıStandartPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.trCamlıStandartPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.trCamlıStandartPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.trCamlıStandartPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.trCamlıStandartPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.trCamlıStandartPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.trCamlıStandartPrice.ttiHizmet,
        },
      ],
      trCityVanUzunSasiModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.trCamlıUzunPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.trCamlıUzunPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.trCamlıUzunPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.trCamlıUzunPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.trCamlıUzunPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.trCamlıUzunPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.trCamlıUzunPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.trCamlıUzunPrice.ttiHizmet,
        },
      ],
      trPanelVanStandartSasiModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.trPanelVanStandartPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.trPanelVanStandartPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.trPanelVanStandartPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.trPanelVanStandartPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.trPanelVanStandartPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.trPanelVanStandartPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.trPanelVanStandartPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.trPanelVanStandartPrice.ttiHizmet,
        },
      ],
      trPanelVanUzunSasiModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.trPanelVanUzun110Price.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.trPanelVanUzun110Price.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.trPanelVanUzun110Price.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.trPanelVanUzun110Price.otv,
        },
        {
          title: "KDV",
          description: this.state.trPanelVanUzun110Price.kdv,
        },
        {
          title: "MTV",
          description: this.state.trPanelVanUzun110Price.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.trPanelVanUzun110Price.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.trPanelVanUzun110Price.ttiHizmet,
        },
      ],
      trPanelVanUzunSasi150ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.trPanelVanUzun150Price.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.trPanelVanUzun150Price.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.trPanelVanUzun150Price.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.trPanelVanUzun150Price.otv,
        },
        {
          title: "KDV",
          description: this.state.trPanelVanUzun150Price.kdv,
        },
        {
          title: "MTV",
          description: this.state.trPanelVanUzun150Price.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.trPanelVanUzun150Price.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.trPanelVanUzun150Price.ttiHizmet,
        },
      ],
      trPikapTekKabinModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.trPikapUzunTekKabinPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.trPikapUzunTekKabinPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.trPikapUzunTekKabinPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.trPikapUzunTekKabinPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.trPikapUzunTekKabinPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.trPikapUzunTekKabinPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.trPikapUzunTekKabinPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.trPikapUzunTekKabinPrice.ttiHizmet,
        },
      ],
      trPikapCiftKabinModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.trPikapUzunCiftKabinPrice.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.trPikapUzunCiftKabinPrice.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.trPikapUzunCiftKabinPrice.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.trPikapUzunCiftKabinPrice.otv,
        },
        {
          title: "KDV",
          description: this.state.trPikapUzunCiftKabinPrice.kdv,
        },
        {
          title: "MTV",
          description: this.state.trPikapUzunCiftKabinPrice.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.trPikapUzunCiftKabinPrice.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.trPikapUzunCiftKabinPrice.ttiHizmet,
        },
      ],
      // caraComfortLineUzunSasi110ModelsDetails: [
      //   {
      //     title: "Anahtar Teslim Fiyatı",
      //     description: this.state.caraComfortlineUzun110.turnkeyPrice,
      //   },
      //   // {
      //   //   title: "Kampanyalı Anahtar Teslim Fiyatı",
      //   //   description: this.state.caraComfortlineUzun110.campaignPrice,
      //   // },
      //   {
      //     title: "Net Fiyat",
      //     description: this.state.caraComfortlineUzun110.netPrice,
      //   },
      //   {
      //     title: "ÖTV",
      //     description: this.state.caraComfortlineUzun110.otv,
      //   },
      //   {
      //     title: "KDV",
      //     description: this.state.caraComfortlineUzun110.kdv,
      //   },
      //   {
      //     title: "MTV",
      //     description: this.state.caraComfortlineUzun110.mtv,
      //   },
      //   {
      //     title: "TTİ Resmi Bedeli*",
      //     description: this.state.caraComfortlineUzun110.ttiResmi,
      //   },
      //   {
      //     title: "TTİ Hizmet Bedeli*",
      //     description: this.state.caraComfortlineUzun110.ttiHizmet,
      //   },
      // ],
      // caraComfortLineUzunSasi150ModelsDetails: [
      //   {
      //     title: "Anahtar Teslim Fiyatı",
      //     description: this.state.caraComfortlineUzun150.turnkeyPrice,
      //   },
      //   // {
      //   //   title: "Kampanyalı Anahtar Teslim Fiyatı",
      //   //   description: this.state.caraComfortlineUzun150.campaignPrice,
      //   // },
      //   {
      //     title: "Net Fiyat",
      //     description: this.state.caraComfortlineUzun150.netPrice,
      //   },
      //   {
      //     title: "ÖTV",
      //     description: this.state.caraComfortlineUzun150.otv,
      //   },
      //   {
      //     title: "KDV",
      //     description: this.state.caraComfortlineUzun150.kdv,
      //   },
      //   {
      //     title: "MTV",
      //     description: this.state.caraComfortlineUzun150.mtv,
      //   },
      //   {
      //     title: "TTİ Resmi Bedeli*",
      //     description: this.state.caraComfortlineUzun150.ttiResmi,
      //   },
      //   {
      //     title: "TTİ Hizmet Bedeli*",
      //     description: this.state.caraComfortlineUzun150.ttiHizmet,
      //   },
      // ],
      caraComfortLineUzunSasi150DSGModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caraComfortlineUzun150DSG.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caraComfortlineUzun150DSG.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caraComfortlineUzun150DSG.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caraComfortlineUzun150DSG.otv,
        },
        {
          title: "KDV",
          description: this.state.caraComfortlineUzun150DSG.kdv,
        },
        {
          title: "MTV",
          description: this.state.caraComfortlineUzun150DSG.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caraComfortlineUzun150DSG.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caraComfortlineUzun150DSG.ttiHizmet,
        },
      ],
      caraHighlineUzunSasi199DSGModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caraHighlineDSG.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caraHighlineDSG.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caraHighlineDSG.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caraHighlineDSG.otv,
        },
        {
          title: "KDV",
          description: this.state.caraHighlineDSG.kdv,
        },
        {
          title: "MTV",
          description: this.state.caraHighlineDSG.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caraHighlineDSG.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caraHighlineDSG.ttiHizmet,
        },
      ],
      caraHighlineUzunSasi199DSG4MotionModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.caraHighlineDSG4Motion.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.caraHighlineDSG4Motion.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.caraHighlineDSG4Motion.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.caraHighlineDSG4Motion.otv,
        },
        {
          title: "KDV",
          description: this.state.caraHighlineDSG4Motion.kdv,
        },
        {
          title: "MTV",
          description: this.state.caraHighlineDSG4Motion.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.caraHighlineDSG4Motion.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.caraHighlineDSG4Motion.ttiHizmet,
        },
      ],
      crafterOkul16ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.crafterOkulELWB16.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterOkulELWB16.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.crafterOkulELWB16.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.crafterOkulELWB16.otv,
        },
        {
          title: "KDV",
          description: this.state.crafterOkulELWB16.kdv,
        },
        {
          title: "MTV",
          description: this.state.crafterOkulELWB16.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.crafterOkulELWB16.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.crafterOkulELWB16.ttiHizmet,
        },
      ],
      crafterOkul19ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.crafterOkulELWB19.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterOkulELWB19.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.crafterOkulELWB19.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.crafterOkulELWB19.otv,
        },
        {
          title: "KDV",
          description: this.state.crafterOkulELWB19.kdv,
        },
        {
          title: "MTV",
          description: this.state.crafterOkulELWB19.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.crafterOkulELWB19.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.crafterOkulELWB19.ttiHizmet,
        },
      ],
      crafterServis16ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.crafterServisELWB16.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterServisELWB16.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.crafterServisELWB16.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.crafterServisELWB16.otv,
        },
        {
          title: "KDV",
          description: this.state.crafterServisELWB16.kdv,
        },
        {
          title: "MTV",
          description: this.state.crafterServisELWB16.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.crafterServisELWB16.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.crafterServisELWB16.ttiHizmet,
        },
      ],
      crafterServis19ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.crafterServisELWB19.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterServisELWB19.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.crafterServisELWB19.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.crafterServisELWB19.otv,
        },
        {
          title: "KDV",
          description: this.state.crafterServisELWB19.kdv,
        },
        {
          title: "MTV",
          description: this.state.crafterServisELWB19.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.crafterServisELWB19.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.crafterServisELWB19.ttiHizmet,
        },
      ],
      crafterPanelVanMWBModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.crafterPanelVanMWB.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterPanelVanMWB.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.crafterPanelVanMWB.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.crafterPanelVanMWB.otv,
        },
        {
          title: "KDV",
          description: this.state.crafterPanelVanMWB.kdv,
        },
        {
          title: "MTV",
          description: this.state.crafterPanelVanMWB.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.crafterPanelVanMWB.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.crafterPanelVanMWB.ttiHizmet,
        },
      ],

      crafterPanelVanLWBModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.crafterPanelVanLWB.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterPanelVanLWB.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.crafterPanelVanLWB.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.crafterPanelVanLWB.otv,
        },
        {
          title: "KDV",
          description: this.state.crafterPanelVanLWB.kdv,
        },
        {
          title: "MTV",
          description: this.state.crafterPanelVanLWB.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.crafterPanelVanLWB.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.crafterPanelVanLWB.ttiHizmet,
        },
      ],

      crafterPanelVanLWB177ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.crafterPanelVanLWB177.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterPanelVanLWB177.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.crafterPanelVanLWB177.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.crafterPanelVanLWB177.otv,
        },
        {
          title: "KDV",
          description: this.state.crafterPanelVanLWB177.kdv,
        },
        {
          title: "MTV",
          description: this.state.crafterPanelVanLWB177.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.crafterPanelVanLWB177.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.crafterPanelVanLWB177.ttiHizmet,
        },
      ],

      crafterPanelVanLWB177ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.crafterPanelVanLWB177.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterPanelVanLWB177.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.crafterPanelVanLWB177.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.crafterPanelVanLWB177.otv,
        },
        {
          title: "KDV",
          description: this.state.crafterPanelVanLWB177.kdv,
        },
        {
          title: "MTV",
          description: this.state.crafterPanelVanLWB177.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.crafterPanelVanLWB177.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.crafterPanelVanLWB177.ttiHizmet,
        },
      ],

      crafterPanelVanLWB177ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.crafterPanelVanLWB177.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterPanelVanLWB177.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.crafterPanelVanLWB177.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.crafterPanelVanLWB177.otv,
        },
        {
          title: "KDV",
          description: this.state.crafterPanelVanLWB177.kdv,
        },
        {
          title: "MTV",
          description: this.state.crafterPanelVanLWB177.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.crafterPanelVanLWB177.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.crafterPanelVanLWB177.ttiHizmet,
        },
      ],

      californiaOcean20TDIDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.californiaOcean20TDI.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.californiaOcean20TDI.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.californiaOcean20TDI.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.californiaOcean20TDI.otv,
        },
        {
          title: "KDV",
          description: this.state.californiaOcean20TDI.kdv,
        },
        {
          title: "MTV",
          description: this.state.californiaOcean20TDI.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.californiaOcean20TDI.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.californiaOcean20TDI.ttiHizmet,
        },
      ],

      californiaOcean20TDI4MotionDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.californiaOcean20TDI4Motion.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.californiaOcean20TDI4Motion.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.californiaOcean20TDI4Motion.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.californiaOcean20TDI4Motion.otv,
        },
        {
          title: "KDV",
          description: this.state.californiaOcean20TDI4Motion.kdv,
        },
        {
          title: "MTV",
          description: this.state.californiaOcean20TDI4Motion.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.californiaOcean20TDI4Motion.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.californiaOcean20TDI4Motion.ttiHizmet,
        },
      ],

      grandCalifornia6004Motion: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.grandCalifornia6004Motion.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.grandCalifornia6004Motion.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.grandCalifornia6004Motion.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.grandCalifornia6004Motion.otv,
        },
        {
          title: "KDV",
          description: this.state.grandCalifornia6004Motion.kdv,
        },
        {
          title: "MTV",
          description: this.state.grandCalifornia6004Motion.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.grandCalifornia6004Motion.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.grandCalifornia6004Motion.ttiHizmet,
        },
      ],

      amarokLifeTDI205: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.amarokLifeTDI205.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokLifeTDI205.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.amarokLifeTDI205.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.amarokLifeTDI205.otv,
        },
        {
          title: "KDV",
          description: this.state.amarokLifeTDI205.kdv,
        },
        {
          title: "MTV",
          description: this.state.amarokLifeTDI205.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.amarokLifeTDI205.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.amarokLifeTDI205.ttiHizmet,
        },
      ],
      amarokStyleTDI205: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.amarokStyleTDI205.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokStyleTDI205.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.amarokStyleTDI205.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.amarokStyleTDI205.otv,
        },
        {
          title: "KDV",
          description: this.state.amarokStyleTDI205.kdv,
        },
        {
          title: "MTV",
          description: this.state.amarokStyleTDI205.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.amarokStyleTDI205.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.amarokStyleTDI205.ttiHizmet,
        },
      ],
      amarokStylePlusTDI205: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.amarokStylePlusTDI205.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokStylePlusTDI205.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.amarokStylePlusTDI205.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.amarokStylePlusTDI205.otv,
        },
        {
          title: "KDV",
          description: this.state.amarokStylePlusTDI205.kdv,
        },
        {
          title: "MTV",
          description: this.state.amarokStylePlusTDI205.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.amarokStylePlusTDI205.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.amarokStylePlusTDI205.ttiHizmet,
        },
      ],
      amarokStyleTDI240: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.amarokStyleTDI240.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokStyleTDI240.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.amarokStyleTDI240.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.amarokStyleTDI240.otv,
        },
        {
          title: "KDV",
          description: this.state.amarokStyleTDI240.kdv,
        },
        {
          title: "MTV",
          description: this.state.amarokStyleTDI240.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.amarokStyleTDI240.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.amarokStyleTDI240.ttiHizmet,
        },
      ],
      amarokPanAmericanoTDI240: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.amarokPanAmericanoTDI240.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokPanAmericanoTDI240.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.amarokPanAmericanoTDI240.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.amarokPanAmericanoTDI240.otv,
        },
        {
          title: "KDV",
          description: this.state.amarokPanAmericanoTDI240.kdv,
        },
        {
          title: "MTV",
          description: this.state.amarokPanAmericanoTDI240.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.amarokPanAmericanoTDI240.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.amarokPanAmericanoTDI240.ttiHizmet,
        },
      ],
      amarokAventuraTDI240: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: this.state.amarokAventuraTDI240.turnkeyPrice,
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokAventuraTDI240.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: this.state.amarokAventuraTDI240.netPrice,
        },
        {
          title: "ÖTV",
          description: this.state.amarokAventuraTDI240.otv,
        },
        {
          title: "KDV",
          description: this.state.amarokAventuraTDI240.kdv,
        },
        {
          title: "MTV",
          description: this.state.amarokAventuraTDI240.mtv,
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: this.state.amarokAventuraTDI240.ttiResmi,
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: this.state.amarokAventuraTDI240.ttiHizmet,
        },
      ],
    };

    const oldCaddyModels = {
      title: "Caddy Modeller",
      description: "Anahtar ve Kampanyalı teslim fiyatları",
      caddyImpressionDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.076.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '610.000 TL',
        // },
        {
          title: "Net Fiyat",
          description: '773.776 TL',
        },
        {
          title: "ÖTV",
          description: '116.066 TL',
        },
        {
          title: "KDV",
          description: '177.968 TL',
        },
        {
          title: "MTV",
          description: '4.053 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      caddyImptessionDSGDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.140.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '650.000 TL',
        // },
        {
          title: "Net Fiyat",
          description: '820.153 TL',
        },
        {
          title: "ÖTV",
          description: '123.023 TL',
        },
        {
          title: "KDV",
          description: '188.635 TL',
        },
        {
          title: "MTV",
          description: '4.053 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      caddyLifeDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.156.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '655.000 TL',
        // },
        {
          title: "Net Fiyat",
          description: '831.747 TL',
        },
        {
          title: "ÖTV",
          description: '124.762 TL',
        },
        {
          title: "KDV",
          description: '191.302 TL',
        },
        {
          title: "MTV",
          description: '4.053 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      caddyLifeDSGDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.248.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '708.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '898.413 TL',
        },
        {
          title: "ÖTV",
          description: '134.762 TL',
        },
        {
          title: "KDV",
          description: '206.635 TL',
        },
        {
          title: "MTV",
          description: '4.053 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      caddyStyleDSGDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.394.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '793.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '1.004.211 TL',
        },
        {
          title: "ÖTV",
          description: '150.632 TL',
        },
        {
          title: "KDV",
          description: '230.968 TL',
        },
        {
          title: "MTV",
          description: '4.053 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      caddyCargo122PSDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '852.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '477.000 TL',
        // },
        {
          title: "Net Fiyat",
          description: '676.455 TL',
        },
        {
          title: "ÖTV",
          description: '27.058 TL',
        },
        {
          title: "KDV",
          description: '140.703 TL',
        },
        {
          title: "MTV",
          description: '3.648 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],

      caddyCargo122PSDSGDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '917.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '521.000 TL',
        // },
        {
          title: "Net Fiyat",
          description: '728.539 TL',
        },
        {
          title: "ÖTV",
          description: '29.142 TL',
        },
        {
          title: "KDV",
          description: '151.536 TL',
        },
        {
          title: "MTV",
          description: '3.648 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      caddyCargoMaxi122PSDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '870.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '483.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '690.878 TL',
        },
        {
          title: "ÖTV",
          description: '27.635 TL',
        },
        {
          title: "KDV",
          description: '143.703 TL',
        },
        {
          title: "MTV",
          description: '3.648 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      caddyCargoMaxi122PSDSGDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '933.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '527.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '741.359 TL',
        },
        {
          title: "ÖTV",
          description: '29.654 TL',
        },
        {
          title: "KDV",
          description: '154.203 TL'
        },
        {
          title: "MTV",
          description: '3.648 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      trCityVanStandartSasiModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.299.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '690.000 TL',
        // },
        {
          title: "Net Fiyat",
          description: '935.370 TL',
        },
        {
          title: "ÖTV",
          description: '140.305 TL',
        },
        {
          title: "KDV",
          description: '215.135 TL',
        },
        {
          title: "MTV",
          description: '4.053 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      trCityVanUzunSasiModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.358.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '745.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '978.124 TL',
        },
        {
          title: "ÖTV",
          description: '146.719 TL',
        },
        {
          title: "KDV",
          description: '224.968 TL',
        },
        {
          title: "MTV",
          description: '4.053 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      trPanelVanStandartSasiModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.062.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '572.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '844.724 TL',
        },
        {
          title: "ÖTV",
          description: '33.789 TL',
        },
        {
          title: "KDV",
          description: '175.703 TL',
        },
        {
          title: "MTV",
          description: '3.648 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      trPanelVanUzunSasiModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.123.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '596.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '893.603 TL',
        },
        {
          title: "ÖTV",
          description: '35.744 TL',
        },
        {
          title: "KDV",
          description: '185.869 TL',
        },
        {
          title: "MTV",
          description: '3.648 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 tL',
        },
      ],
      trPanelVanUzunSasi150ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.150.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '632.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '915.237 TL',
        },
        {
          title: "ÖTV",
          description: '36.609 TL',
        },
        {
          title: "KDV",
          description: '190.369 TL',
        },
        {
          title: "MTV",
          description: '3.648 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      trPikapTekKabinModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.085.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '593.000 TL',
        // },
        {
          title: "Net Fiyat",
          description: '863.154 TL',
        },
        {
          title: "ÖTV",
          description: '34.526 TL',
        },
        {
          title: "KDV",
          description: '179.536 TL',
        },
        {
          title: "MTV",
          description: '3.648 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      trPikapCiftKabinModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.150.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '628.000 TL',
        // },
        {
          title: "Net Fiyat",
          description: '915.237 TL',
        },
        {
          title: "ÖTV",
          description: '36.609 TL',
        },
        {
          title: "KDV",
          description: '190.369 TL',
        },
        {
          title: "MTV",
          description: '3.648 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      caraComfortLineUzunSasi110ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '970.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '903.000 TL',
        // },
        {
          title: "Net Fiyat",
          description: '662.874 TL',
        },
        {
          title: "ÖTV",
          description: '99.431 TL',
        },
        {
          title: "KDV",
          description: '137.215 TL',
        },
        {
          title: "MTV",
          description: '1.268 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.139 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '1.073 TL',
        },
      ],
      // caraComfortLineUzunSasi150ModelsDetails: [
      //   {
      //     title: "Anahtar Teslim Fiyatı",
      //     description: '1.055.500 TL',
      //   },
      //   // {
      //   //   title: "Kampanyalı Anahtar Teslim Fiyatı",
      //   //   description: '988.500 TL',
      //   // },
      //   {
      //     title: "Net Fiyat",
      //     description: '725.881 TL',
      //   },
      //   {
      //     title: "ÖTV",
      //     description: '108.882 TL',
      //   },
      //   {
      //     title: "KDV",
      //     description: '150.257 TL',
      //   },
      //   {
      //     title: "MTV",
      //     description: '1.268 TL',
      //   },
      //   {
      //     title: "TTİ Resmi Bedeli*",
      //     description: '1.139 TL',
      //   },
      //   {
      //     title: "TTİ Hizmet Bedeli*",
      //     description: '1.073 TL',
      //   },
      // ],
      caraComfortLineUzunSasi150DSGModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '2.114.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '1.042.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '1.527.432 TL',
        },
        {
          title: "ÖTV",
          description: '229.115 TL',
        },
        {
          title: "KDV",
          description: '351.309 TL',
        },
        {
          title: "MTV",
          description: '2.008 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      caraHighlineUzunSasi199DSGModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '2.496.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '1.202.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '1.804.243 TL',
        },
        {
          title: "ÖTV",
          description: '270.636 TL',
        },
        {
          title: "KDV",
          description: '414.976 TL',
        },
        {
          title: "MTV",
          description: '2.008 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      caraHighlineUzunSasi199DSG4MotionModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '2.818.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '1.391.000 TL',
        // },
        {
          title: "Net Fiyat",
          description: '2.031.947 TL',
        },
        {
          title: "ÖTV",
          description: '304.792 TL',
        },
        {
          title: "KDV",
          description: '467.348 TL',
        },
        {
          title: "MTV",
          description: '9.777 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],
      crafterServis16ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: "2.058.000 TL",
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterServisELWB16.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: "1.644.115 TL",
        },
        {
          title: "ÖTV",
          description: "65.765 TL",
        },
        {
          title: "KDV",
          description: "341.976 TL",
        },
        {
          title: "MTV",
          description: "2.008 TL",
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: "1.836 TL",
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: "2.300 TL",
        },
      ],
      crafterServis19ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: "2.135.000 TL",
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterServisELWB19.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: "1.703.358 TL",
        },
        {
          title: "ÖTV",
          description: "68.134 TL",
        },
        {
          title: "KDV",
          description: "354.298 TL",
        },
        {
          title: "MTV",
          description: "5.073 TL",
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: "1.836 TL",
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: "2.300 TL",
        },
      ],

      crafterOkul16ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: "2.134.000 TL",
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterOkulELWB16.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: "1.705.012 TL",
        },
        {
          title: "ÖTV",
          description: "68.200 TL",
        },
        {
          title: "KDV",
          description: "354.643 TL",
        },
        {
          title: "MTV",
          description: "2.008 TL",
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: "1.836 TL",
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: "2.300 TL",
        },
      ],
      crafterOkul19ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: "2.277.000 TL",
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterOkulELWB19.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: "1.817.140 TL",
        },
        {
          title: "ÖTV",
          description: "72.686 TL",
        },
        {
          title: "KDV",
          description: "377.965 TL",
        },
        {
          title: "MTV",
          description: "5.073 TL",
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: "1.836 TL",
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: "2.300 TL",
        },
      ],
      crafterPanelVanMWBModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.228.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '675.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '977.737 TL',
        },
        {
          title: "ÖTV",
          description: '39.109 TL',
        },
        {
          title: "KDV",
          description: '203.369 TL',
        },
        {
          title: "MTV",
          description: '3.648 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],

      crafterPanelVanLWBModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: '1.300.000 TL',
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: '681.500 TL',
        // },
        {
          title: "Net Fiyat",
          description: '1.035.430 TL',
        },
        {
          title: "ÖTV",
          description: '41.417 TL',
        },
        {
          title: "KDV",
          description: '215.369 TL',
        },
        {
          title: "MTV",
          description: '3.648 TL',
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: '1.836 TL',
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: '2.300 TL',
        },
      ],

      crafterPanelVanLWB177ModelsDetails: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: "1.355.000 TL",
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.crafterPanelVanLWB177.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: "1.079.500 TL",
        },
        {
          title: "ÖTV",
          description: "43.180 TL",
        },
        {
          title: "KDV",
          description: "224.536 TL",
        },
        {
          title: "MTV",
          description: "3.648 TL",
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: "1.836 TL",
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: "2.300 TL",
        },
      ],

      amarokLifeTDI205: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: "2.200.000 TL",
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokLifeTDI205.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: "1.751.672 TL"
        },
        {
          title: "ÖTV",
          description: "70.067 TL",
        },
        {
          title: "KDV",
          description: "364.348 TL",
        },
        {
          title: "MTV",
          description: "9.777 TL",
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: "1.836 TL",
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: "2.300 TL",
        },
      ],
      amarokStyleTDI205: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: "2.335.000 TL",
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokStyleTDI205.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: "1.859.845 TL",
        },
        {
          title: "ÖTV",
          description: "74.394 TL",
        },
        {
          title: "KDV",
          description: "386.848 TL",
        },
        {
          title: "MTV",
          description: "9.777 TL",
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: "1.836 TL",
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: "2.300 TL",
        },
      ],
      amarokStylePlusTDI205: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: "2.521.000 TL",
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokStylePlusTDI205.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: "2.008.884 TL",
        },
        {
          title: "ÖTV",
          description: "80.355 TL",
        },
        {
          title: "KDV",
          description: "417.848 TL",
        },
        {
          title: "MTV",
          description: "9.777 TL",
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: "1.836 TL",
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: "2.300 TL",
        },
      ],
      amarokStyleTDI240: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: "2.576.000 TL",
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokStyleTDI240.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: "2.044.403 TL",
        },
        {
          title: "ÖTV",
          description: "81.776 TL",
        },
        {
          title: "KDV",
          description: "425.236 TL",
        },
        {
          title: "MTV",
          description: "20.449 TL",
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: "1.836 TL",
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: "2.300 TL",
        },
      ],
      amarokPanAmericanoTDI240: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: "2.738.000 TL",
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokPanAmericanoTDI240.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: "2.174.210 TL",
        },
        {
          title: "ÖTV",
          description: "86.968 TL",
        },
        {
          title: "KDV",
          description: "452.236 TL",
        },
        {
          title: "MTV",
          description: "20.449 TL",
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: "1.836 TL",
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: "2.300 TL",
        },
      ],
      amarokAventuraTDI240: [
        {
          title: "Anahtar Teslim Fiyatı",
          description: "2.896.000 TL",
        },
        // {
        //   title: "Kampanyalı Anahtar Teslim Fiyatı",
        //   description: this.state.amarokAventuraTDI240.campaignPrice,
        // },
        {
          title: "Net Fiyat",
          description: "2.300.813 TL",
        },
        {
          title: "ÖTV",
          description: "92.033 TL",
        },
        {
          title: "KDV",
          description: "478.569 TL",
        },
        {
          title: "MTV",
          description: "20.449 TL",
        },
        {
          title: "TTİ Resmi Bedeli*",
          description: "1.836 TL",
        },
        {
          title: "TTİ Hizmet Bedeli*",
          description: "2.300 TL",
        },
      ],
    };

    return (
      <>
        <BreakpointWrapper max={Breakpoints.b560}>
          {this.renderMobileViews(
            selectedIndex,
            selectedYearIndex,
            caddyModels,
            oldCaddyModels,
            caddyOptional,
            oldCaddyOptional,
            transporterOptional,
            oldTransporterOptional,
            caravelleOptional,
            crafterOptional,
            oldCrafterOptional,
          )}
          <MobileAppDownloadSection />
        </BreakpointWrapper>
        <BreakpointWrapper min={Breakpoints.b560}>
          {this.renderDesktopViews(
            selectedIndex,
            selectedYearIndex,
            oldCaddyModels,
            caddyModels,
            caddyOptional,
            oldCaddyOptional,
            transporterOptional,
            oldTransporterOptional,
            caravelleOptional,
            crafterOptional,
            oldCrafterOptional,
          )}
          <MobileAppDownloadSection />
        </BreakpointWrapper>
      </>
    );
  }

  renderMobileViews(
    selectedIndex,
    selectedYearIndex,
    caddyModels,
    oldCaddyModels,
    caddyOptional,
    oldCaddyOptional,
    transporterOptional,
    oldTransporterOptional,
    caravelleOptional,
    crafterOptional,
    oldCrafterOptional,
  ) {
    const {
      custom,
      custom1,
      custom2,
      custom3,
      custom4,
      custom5,
      custom6,
      custom7,
      custom8,
      custom9,
      custom10,
      custom11,
      custom12,
      custom13,
      custom14,
      custom15,
    } = this.props;
    const isMobile = typeof window !== `undefined` && window.innerWidth <= 768;
    if (selectedYearIndex == 1) {

      if (selectedIndex === 0 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.caddyStyleDSGDetails}
              carName={"Caddy Style"}
              modelNameOne={"Caddy 2.0 TDI 122 PS Style DSG"}
              kafone={this.state.caddyStyleDSGPrice.campaignPrice}
              containerColor={"#b99e8c"}
              carImage={1}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.caddyLifeDSGDetails}
              // dataTwo={oldCaddyModels.caddyLifeDSGDetails}
              // modelNameOne={"Caddy 2.0 TDI 122 PS Life"}
              modelNameOne={"Caddy 2.0 TDI 122 PS Life DSG"}
              kafone={this.state.caddyLifeDSGPrice.campaignPrice}
              carName={"Caddy Life"}
              containerColor={"#1a6789"}
              carImage={2}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.caddyImptessionDSGDetails}
              modelNameOne={"Caddy 2.0 TDI 122 PS Impression DSG"}
              carName={"Caddy Impression"}
              kafone={this.state.caddyImpressionDSGPrice.campaignPrice}
              containerColor={"#bebebe"}
              carImage={3}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              // data={caddyModels.caddyCargo122PSDetails}
              data={caddyModels.caddyCargo122PSDSGDetails}
              // dataThree={caddyModels.caddyCargoMaxi122PSDetails}
              dataTwo={caddyModels.caddyCargoMaxi122PSDSGDetails}
              // modelNameOne={"Caddy Cargo 2.0 TDI 122 PS"}
              modelNameOne={"Caddy Cargo 2.0 TDI 122 PS DSG"}
              // modelNameThree={"Caddy Cargo Maxi 2.0 TDI 122 PS"}
              modelNameTwo={"Caddy Cargo Maxi 2.0 TDI 122 PS DSG"}
              carName={"Caddy Cargo"}
              // kafone={this.state.caddyCargoPSPrice.campaignPrice}
              kafone={this.state.caddyCargoPSDSGPrice.campaignPrice}
              // kafthree={this.state.caddyCargoMaxiPSPrice.campaignPrice}
              kaftwo={this.state.caddyCargoMaxiPSDSGPrice.campaignPrice}
              containerColor={"#bebebe"}
              carImage={4}
            />
            <br />
            <OptionalAccordion
              optional={caddyOptional.caddyOptionalDetails}
              isOpen={true}
              icon={false}
            />
            {this.renderCaddyNotesTexts(0)}
          </Container>
        );
      } else if (selectedIndex === 1 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.trCityVanStandartSasiModelsDetails}
              dataTwo={caddyModels.trCityVanUzunSasiModelsDetails}
              carName={"Transporter Kombi"}
              modelNameOne={
                "Transporter City Van (5+1)/Camlı Van (4+1) 2.0 TDI Standart Şasi 150PS "
              }
              modelNameTwo={
                "Transporter City Van (5+1)/Camlı Van (4+1) 2.0 TDI Uzun Şasi 150PS"
              }
              kafone={this.state.trCamlıStandartPrice.campaignPrice}
              kaftwo={this.state.trCamlıUzunPrice.campaignPrice}
              containerColor={"#a0a0a0"}
              carImage={5}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.trPanelVanStandartSasiModelsDetails}
              dataTwo={caddyModels.trPanelVanUzunSasiModelsDetails}
              dataThree={caddyModels.trPanelVanUzunSasi150ModelsDetails}
              modelNameOne={
                "Transporter Panel Van 2.0 TDI (2+1) Standart Şasi 110PS"
              }
              modelNameTwo={
                "Transporter Panel Van 2.0 TDI (2+1) Uzun Şasi 110PS "
              }
              modelNameThree={
                "Transporter Panel Van 2.0 TDI (2+1) Uzun Şasi 150PS"
              }
              kafone={this.state.trPanelVanStandartPrice.campaignPrice}
              kaftwo={this.state.trPanelVanUzun110Price.campaignPrice}
              kafthree={this.state.trPanelVanUzun150Price.campaignPrice}
              carName={"Transporter Panel Van"}
              containerColor={"#bebebe"}
              carImage={6}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.trPikapTekKabinModelsDetails}
              dataTwo={caddyModels.trPikapCiftKabinModelsDetails}
              modelNameOne={
                "Transporter Pikap 2.0 TDI (2+1) Uzun Şasi Tek Kabin 110PS"
              }
              modelNameTwo={
                "Transporter Pikap 2.0 TDI (5+1) Uzun Şasi Çift Kabin 110PS"
              }
              carName={"Transporter Pikap"}
              kafone={this.state.trPikapUzunTekKabinPrice.campaignPrice}
              kaftwo={this.state.trPikapUzunCiftKabinPrice.campaignPrice}
              containerColor={"#bebebe"}
              carImage={7}
            />
            <br />
            <OptionalAccordion
              optional={transporterOptional.transporterOptionalDetails}
              isOpen={true}
              icon={true}
            />
            {this.renderTransporterTexts(0)}
          </Container>
        );
      } else if (selectedIndex === 2 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.caraHighlineUzunSasi199DSGModelsDetails}
              dataTwo={caddyModels.caraHighlineUzunSasi199DSG4MotionModelsDetails}
              carName={"Caravelle Highline"}
              modelNameOne={
                "Caravelle Highline 2.0 TDI (8+1) Uzun Şasi 204 PS DSG"
              }
              modelNameTwo={
                "Caravelle Highline 2.0 TDI (8+1) Uzun Şasi 204 PS DSG 4Motion"
              }
              kafone={this.state.caraHighlineDSG.campaignPrice}
              kaftwo={this.state.caraHighlineDSG4Motion.campaignPrice}
              containerColor={"#990009"}
              carImage={9}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              // data={caddyModels.caraComfortLineUzunSasi150ModelsDetails}
              dataTwo={caddyModels.caraComfortLineUzunSasi150DSGModelsDetails}
              // dataThree={caddyModels.caraComfortLineUzunSasi110ModelsDetails}
              carName={"Caravelle Comfortline"}
              // modelNameOne={"Caravelle Comfortline 2.0 TDI Uzun Şasi 150PS"}
              modelNameTwo={"Caravelle Comfortline 2.0 TDI Uzun Şasi 150PS DSG"}
              // modelNameThree={
              //   "Caravelle Comfortline 2.0 TDI (8+1) Uzun Şasi 110 PS"
              // }
              // kafone={this.state.caraComfortlineUzun150.campaignPrice}
              kaftwo={this.state.caraComfortlineUzun150DSG.campaignPrice}
              // kafthree={this.state.caraComfortlineUzun110.campaignPrice}
              containerColor={"#327396"}
              carImage={8}
            />
            <br />
            {/* <OptionalAccordion
              optional={caravelleOptional.caravelleOptionalDetails}
              isOpen={true}
              icon={true}
            /> */}
            {this.renderCaravelleTexts(0)}
          </Container>
        );
      }
      // else if (selectedIndex === 3 && isMobile) {
      //   return (
      //     <Container
      //       horizontalAlign={ContainerHorizontalAlignment.center}
      //       wrap={ContainerWrap.always}
      //       padding={{ top: "10px" }}
      //     >
      //       <br />
      //       <br />
      //       <br />
      //       <br />
      //       <PriceCard
      //         isSpecialFooter={false}
      //         data={caddyModels.multiVaneHybrid218TSIDetails}
      //         kafone={this.state.multiVanTSI218.campaignPrice}
      //         carName={"Multivan"}
      //         modelNameOne={
      //           "Yeni Multivan Style 1.4 TSI eHybrid (6+1) Uzun Şasi 218 PS DSG"
      //         }
      //         containerColor={"#91969d"}
      //         carImage={24}
      //       />
      //       <br />
      //       <OptionalAccordion
      //         optional={[
      //           {
      //             title: "Metalik Renk",
      //             description: this.state.opsiyonelMultiVanOne,
      //           },
      //           {
      //             title: "Çift Renk",
      //             description: this.state.opsiyonelMultiVanTwo,
      //           },
      //         ]}
      //         isOpen={true}
      //         icon={false}
      //       />
      //       {this.renderMultiVanText()}
      //     </Container>
      //   );
      // }
      else if (selectedIndex === 3 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.crafterServis16ModelsDetails}
              dataTwo={caddyModels.crafterServis19ModelsDetails}
              kafone={this.state.crafterServisELWB16.campaignPrice}
              kaftwo={this.state.crafterServisELWB19.campaignPrice}
              carName={"Crafter Servis"}
              modelNameOne={
                "Crafter Servis 2.0 TDI (16+1) ELWB HR 163 PS Comfortline 4.9T"
              }
              modelNameTwo={
                "Crafter Servis 2.0 TDI (19+1) ELWB HR 163 PS Comfortline 4.9T"
              }
              containerColor={"#91969d"}
              carImage={13}
            />
            <br />
            <PriceCard
              data={caddyModels.crafterOkul16ModelsDetails}
              dataTwo={caddyModels.crafterOkul19ModelsDetails}
              containerColor={'#dedede'}
              kafone={this.state.crafterOkulELWB16.campaignPrice}
              kaftwo={this.state.crafterOkulELWB19.campaignPrice}
              isSpecialFooter={false}
              carName={'Crafter Okul'}
              modelNameOne={'Crafter Okul 2.0 TDI (16+1) ELWB HR 163 PS Comfortline 4.9T'}
              modelNameTwo={'Crafter Okul 2.0 TDI (19+1) ELWB HR 163 PS Comfortline 4.9T'}
              carImage={14} /><br />
            <br />

            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.crafterPanelVanMWBModelsDetails}
              dataTwo={caddyModels.crafterPanelVanLWBModelsDetails}
              dataThree={caddyModels.crafterPanelVanLWB177ModelsDetails}
              modelNameOne={
                "Crafter Panel Van 2.0 TDI (2+1) MWB HR 140 PS 3,5 T Comfortline"
              }
              modelNameTwo={
                "Crafter Panel Van 2.0 TDI (2+1) LWB HR 140 PS 3,5 T Comfortline"
              }
              modelNameThree={
                "Crafter Panel Van 2.0 TDI (2+1) LWB HR 177 PS 3,5 T Comfortline"
              }
              containerColor={"#bebebe"}
              kafone={this.state.crafterPanelVanMWB.campaignPrice}
              kaftwo={this.state.crafterPanelVanLWB.campaignPrice}
              kafthree={this.state.crafterPanelVanLWB177.campaignPrice}
              carName={"Crafter Panel Van"}
              carImage={15}
              oldCarPrice={1}
            />
            <br />
            <OptionalAccordion
              optional={crafterOptional?.crafterOptionalDetails}
              isOpen={true}
              icon={false}
            />
            {this.renderCrafterTexts(0)}
          </Container>
        );
      }
      else if (selectedIndex === 4 && isMobile) {
        return (
          // <Container
          //   padding={padding4Dimensions}
          //   horizontalAlign={ContainerHorizontalAlignment.center}
          // >
          //   <h2>
          //     <Text
          //       textAlign={TextAlignment.center}
          //       appearance={TextAppearance.headline0400}
          //     >
          //       Fiyatlarımızı&nbsp;
          //       <br />
          //       <Text bold appearance={TextAppearance.headline0400}>
          //         güncelliyoruz
          //       </Text>
          //     </Text>
          //   </h2>
          // </Container>         
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.amarokAventuraTDI240}
              modelNameOne={
                "Amarok V6 3.0 TDI 240 PS Aventura Oto. 4Motion"
              }
              containerColor={"#2888c4"}
              kafone={this.state.amarokAventuraTDI240.campaignPrice}
              carName={"Amarok Aventura"}
              carImage={19}
              oldCarPrice={1}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.amarokPanAmericanoTDI240}
              modelNameOne={
                "Amarok V6 3.0 TDI 240 PS PanAmericana Oto. 4Motion"
              }
              containerColor={"#a69c8f"}
              kafone={this.state.amarokPanAmericanoTDI240.campaignPrice}
              carName={"Amarok PanAmericana"}
              carImage={20}
              oldCarPrice={1}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.amarokStylePlusTDI205}
              modelNameOne={
                "Amarok 2.0 TDI 205 PS Style Plus Oto. 4Motion"
              }
              containerColor={"#bcbebd"}
              kafone={this.state.amarokStylePlusTDI205.campaignPrice}
              carName={"Amarok Style Plus"}
              carImage={21}
              oldCarPrice={1}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.amarokStyleTDI205}
              dataTwo={caddyModels.amarokStyleTDI240}
              modelNameOne={
                "Amarok 2.0 TDI 205 PS Style Oto. 4Motion"
              }
              modelNameTwo={
                "Amarok V6 3.0 TDI 240 PS Style Oto. 4Motion"
              }
              containerColor={"#a82a2d"}
              kafone={this.state.amarokStyleTDI205.campaignPrice}
              kaftwo={this.state.amarokStyleTDI240.campaignPrice}
              carName={"Amarok Style"}
              carImage={22}
              oldCarPrice={1}
            />
            <br />
            {/* <PriceCard
              isSpecialFooter={false}
              data={caddyModels.amarokLifeTDI205}
              modelNameOne={
                "Amarok 2.0 TDI 205 PS Life Oto. 4Motion"
              }
              containerColor={"#848688"}
              kafone={this.state.amarokLifeTDI205.campaignPrice}
              carName={"Amarok Life"}
              carImage={23}
              oldCarPrice={1}
            /> */}
            <br />
            {this.renderAmarokText()}
          </Container>
        );
      }

      else if (selectedIndex === 5 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            <br />
            <PriceCard
              isSpecialFooter={true}
              // data={[
              //   {
              //     title: "Anahtar Teslim Fiyatı",
              //     description: this.state.californiaOcean20TDI.turnkeyPrice,
              //   },
              //   // {
              //   //   title: "Kampanyalı Anahtar Teslim Fiyatı",
              //   //   description: this.state.californiaOcean20TDI.campaignPrice,
              //   // },
              //   {
              //     title: "Net Fiyat",
              //     description: this.state.californiaOcean20TDI.netPrice,
              //   },
              //   {
              //     title: "ÖTV",
              //     description: this.state.californiaOcean20TDI.otv,
              //   },
              //   {
              //     title: "KDV",
              //     description: this.state.californiaOcean20TDI.kdv,
              //   },
              //   {
              //     title: "MTV",
              //     description: this.state.californiaOcean20TDI.mtv,
              //   },
              //   {
              //     title: "TTİ Resmi Bedeli*",
              //     description: this.state.californiaOcean20TDI.ttiResmi,
              //   },
              //   {
              //     title: "TTİ Hizmet Bedeli*",
              //     description: this.state.californiaOcean20TDI.ttiHizmet,
              //   },
              // ]}
              data={[
                {
                  title: "Anahtar Teslim Fiyatı",
                  description: this.state.californiaOcean20TDI4Motion.turnkeyPrice,
                },
                {
                  title: "Kampanyalı Anahtar Teslim Fiyatı",
                  description: this.state.californiaOcean20TDI4Motion.campaignPrice,
                },
                {
                  title: "Net Fiyat",
                  description: this.state.californiaOcean20TDI4Motion.netPrice,
                },
                {
                  title: "ÖTV",
                  description: this.state.californiaOcean20TDI4Motion.otv,
                },
                {
                  title: "KDV",
                  description: this.state.californiaOcean20TDI4Motion.kdv,
                },
                {
                  title: "MTV",
                  description: this.state.californiaOcean20TDI4Motion.mtv,
                },
                {
                  title: "TTİ Resmi Bedeli*",
                  description: this.state.californiaOcean20TDI4Motion.ttiResmi,
                },
                {
                  title: "TTİ Hizmet Bedeli*",
                  description: this.state.californiaOcean20TDI4Motion.ttiHizmet,
                },
              ]}
              // modelNameOne={
              //   "California Ocean 2.0 TDI 204 PS DSG"
              // }
              modelNameOne={
                "California Ocean 2.0 TDI 204 PS DSG 4Motion"
              }
              containerColor={"#bebebe"}
              kafone={this.state.californiaOcean20TDI.campaignPrice}
              kaftwo={this.state.californiaOcean20TDI4Motion.campaignPrice}
              carName={"California Ocean"}
              isStrike={true}
              carImage={16}
            />
            <br />
            <OptionalAccordion
              optional={[
                {
                  title: "Metalik Renk",
                  description: this.state.opsiyonelCaliforniaOceanOne,
                },
                {
                  title: "Çift Renk*",
                  description: this.state.opsiyonelCaliforniaOceanTwo,
                },

              ]}
              isOpen={true}
              icon={false}
            />
            {this.renderCaliforniaText()}
          </Container>
        );
      }
      else if (selectedIndex === 6 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            <br />
            <PriceCard
              isSpecialFooter={true}
              data={[
                {
                  title: "Anahtar Teslim Fiyatı",
                  description: this.state.grandCalifornia6004Motion.turnkeyPrice,
                },
                {
                  title: "Kampanyalı Anahtar Teslim Fiyatı",
                  description: this.state.grandCalifornia6004Motion.campaignPrice,
                },
                {
                  title: "Net Fiyat",
                  description: this.state.grandCalifornia6004Motion.netPrice,
                },
                {
                  title: "ÖTV",
                  description: this.state.grandCalifornia6004Motion.otv,
                },
                {
                  title: "KDV",
                  description: this.state.grandCalifornia6004Motion.kdv,
                },
                {
                  title: "MTV",
                  description: this.state.grandCalifornia6004Motion.mtv,
                },
                {
                  title: "TTİ Resmi Bedeli*",
                  description: this.state.grandCalifornia6004Motion.ttiResmi,
                },
                {
                  title: "TTİ Hizmet Bedeli*",
                  description: this.state.grandCalifornia6004Motion.ttiHizmet,
                },
              ]}
              modelNameOne={
                "Grand California 600 2.0 TDI 177 PS Oto. 4Motion"
              }
              containerColor={"#bebebe"}
              kafone={this.state.grandCalifornia6004Motion.campaignPrice}
              carName={"Grand California"}
              isStrike={true}
              carImage={18}
            />
            <br />
            <OptionalAccordion
              optional={[
                {
                  title: "Metalik Renk",
                  description: this.state.opsiyonelGrandCaliforniaOne,
                },
                {
                  title: "Çift Renk*",
                  description: this.state.opsiyonelGrandCaliforniaTwo,
                },
              ]}
              isOpen={true}
              icon={false}
            />
            {this.renderGrandCaliforniaText()}
          </Container>
        );
      }
    } else if (selectedYearIndex == 0) {


      if (selectedIndex === 0 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.caddyStyleDSGDetails}
              carName={"Caddy Style"}
              modelNameOne={"Caddy 2.0 TDI 122 PS Style DSG"}
              kafone={this.state.caddyStyleDSGPrice.campaignPrice}
              containerColor={"#b99e8c"}
              carImage={1}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.caddyLifeDetails}
              dataTwo={oldCaddyModels.caddyLifeDSGDetails}
              modelNameOne={"Caddy 2.0 TDI 122 PS Life"}
              modelNameTwo={"Caddy 2.0 TDI 122 PS Life DSG"}
              kafone={this.state.caddyLifePrice.campaignPrice}
              kaftwo={this.state.caddyLifeDSGPrice.campaignPrice}
              carName={"Caddy Life"}
              containerColor={"#1a6789"}
              carImage={2}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.caddyImpressionDetails}
              dataTwo={oldCaddyModels.caddyImptessionDSGDetails}
              modelNameOne={"Caddy 2.0 TDI 122 PS Impression"}
              modelNameTwo={"Caddy 2.0 TDI 122 PS Impression DSG"}
              carName={"Caddy Impression"}
              kafone={this.state.caddyImpressionPrice.campaignPrice}
              kaftwo={this.state.caddyImpressionDSGPrice.campaignPrice}
              containerColor={"#bebebe"}
              carImage={3}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.caddyCargo122PSDetails}
              dataTwo={oldCaddyModels.caddyCargo122PSDSGDetails}
              dataThree={oldCaddyModels.caddyCargoMaxi122PSDetails}
              dataFour={oldCaddyModels.caddyCargoMaxi122PSDSGDetails}
              modelNameOne={"Caddy Cargo 2.0 TDI 122 PS"}
              modelNameTwo={"Caddy Cargo 2.0 TDI 122 PS DSG"}
              modelNameThree={"Caddy Cargo Maxi 2.0 TDI 122 PS"}
              modelNameFour={"Caddy Cargo Maxi 2.0 TDI 122 PS DSG"}
              carName={"Caddy Cargo"}
              kafone={this.state.caddyCargoPSPrice.campaignPrice}
              kaftwo={this.state.caddyCargoPSDSGPrice.campaignPrice}
              kafthree={this.state.caddyCargoMaxiPSPrice.campaignPrice}
              kaffour={this.state.caddyCargoMaxiPSDSGPrice.campaignPrice}
              containerColor={"#bebebe"}
              carImage={4}
            />
            <br />
            <OptionalAccordion
              optional={oldCaddyOptional.caddyOptionalDetails}
              isOpen={true}
              icon={false}
            />
            {this.renderCaddyNotesTexts(0)}
          </Container>
        );
      } else if (selectedIndex === 1 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.trCityVanStandartSasiModelsDetails}
              dataTwo={oldCaddyModels.trCityVanUzunSasiModelsDetails}
              carName={"Transporter Kombi"}
              modelNameOne={
                "Transporter City Van (5+1)/Camlı Van (4+1) 2.0 TDI Standart Şasi 150PS "
              }
              modelNameTwo={
                "Transporter City Van (5+1)/Camlı Van (4+1) 2.0 TDI Uzun Şasi 150PS"
              }
              kafone={this.state.trCamlıStandartPrice.campaignPrice}
              kaftwo={this.state.trCamlıUzunPrice.campaignPrice}
              containerColor={"#a0a0a0"}
              carImage={5}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.trPanelVanStandartSasiModelsDetails}
              dataTwo={oldCaddyModels.trPanelVanUzunSasiModelsDetails}
              dataThree={oldCaddyModels.trPanelVanUzunSasi150ModelsDetails}
              modelNameOne={
                "Transporter Panel Van 2.0 TDI (2+1) Standart Şasi 110PS"
              }
              modelNameTwo={
                "Transporter Panel Van 2.0 TDI (2+1) Uzun Şasi 110PS "
              }
              modelNameThree={
                "Transporter Panel Van 2.0 TDI (2+1) Uzun Şasi 150PS"
              }
              kafone={this.state.trPanelVanStandartPrice.campaignPrice}
              kaftwo={this.state.trPanelVanUzun110Price.campaignPrice}
              kafthree={this.state.trPanelVanUzun150Price.campaignPrice}
              carName={"Transporter Panel Van"}
              containerColor={"#bebebe"}
              carImage={6}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.trPikapTekKabinModelsDetails}
              dataTwo={oldCaddyModels.trPikapCiftKabinModelsDetails}
              modelNameOne={
                "Transporter Pikap 2.0 TDI (2+1) Uzun Şasi Tek Kabin 110PS"
              }
              modelNameTwo={
                "Transporter Pikap 2.0 TDI (5+1) Uzun Şasi Çift Kabin 110PS"
              }
              carName={"Transporter Pikap"}
              kafone={this.state.trPikapUzunTekKabinPrice.campaignPrice}
              kaftwo={this.state.trPikapUzunCiftKabinPrice.campaignPrice}
              containerColor={"#bebebe"}
              carImage={7}
            />
            <br />
            <OptionalAccordion
              optional={oldTransporterOptional.transporterOptionalDetails}
              isOpen={true}
              icon={true}
            />
            {this.renderTransporterTexts(0)}
          </Container>
        );
      } else if (selectedIndex === 2 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={caddyModels.caraHighlineUzunSasi199DSGModelsDetails}
              dataTwo={caddyModels.caraHighlineUzunSasi199DSG4MotionModelsDetails}
              carName={"Caravelle Highline"}
              modelNameOne={
                "Caravelle Highline 2.0 TDI (8+1) Uzun Şasi 204 PS DSG"
              }
              modelNameTwo={
                "Caravelle Highline 2.0 TDI (8+1) Uzun Şasi 204 PS DSG 4Motion"
              }
              kafone={this.state.caraHighlineDSG.campaignPrice}
              kaftwo={this.state.caraHighlineDSG4Motion.campaignPrice}
              containerColor={"#990009"}
              carImage={9}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              // data={caddyModels.caraComfortLineUzunSasi150ModelsDetails}
              dataTwo={caddyModels.caraComfortLineUzunSasi150DSGModelsDetails}
              // dataThree={caddyModels.caraComfortLineUzunSasi110ModelsDetails}
              carName={"Caravelle Comfortline"}
              // modelNameOne={"Caravelle Comfortline 2.0 TDI Uzun Şasi 150PS"}
              modelNameTwo={"Caravelle Comfortline 2.0 TDI Uzun Şasi 150PS DSG"}
              // modelNameThree={
              //   "Caravelle Comfortline 2.0 TDI (8+1) Uzun Şasi 110 PS"
              // }
              // kafone={this.state.caraComfortlineUzun150.campaignPrice}
              kaftwo={this.state.caraComfortlineUzun150DSG.campaignPrice}
              // kafthree={this.state.caraComfortlineUzun110.campaignPrice}
              containerColor={"#327396"}
              carImage={8}
            />
            <br />
            {/* <OptionalAccordion
              optional={caravelleOptional.caravelleOptionalDetails}
              isOpen={true}
              icon={true}
            /> */}
            {this.renderCaravelleTexts(0)}
          </Container>
        );
      } 
      // else if (selectedIndex === 3 && isMobile) {
      //   return (
      //     <Container
      //       horizontalAlign={ContainerHorizontalAlignment.center}
      //       wrap={ContainerWrap.always}
      //       padding={{ top: "10px" }}
      //     >
      //       <br />
      //       <br />
      //       <br />
      //       <br />
      //       <PriceCard
      //         isSpecialFooter={true}
      //         data={[
      //           {
      //             title: "Anahtar Teslim Fiyatı",
      //             description: "4.100.000 TL",
      //           },
      //           {
      //             title: "Kampanyalı Anahtar Teslim Fiyatı",
      //             description: "3.450.000 TL",
      //           },
      //           {
      //             title: "Net Fiyat",
      //             description: "1.593.681 TL",
      //           },
      //           {
      //             title: "ÖTV",
      //             description: "1.274.945 TL",
      //           },
      //           {
      //             title: "KDV",
      //             description: "573.725 TL",
      //           },
      //           {
      //             title: "MTV",
      //             description: "3.513 TL",
      //           },
      //           {
      //             title: "TTİ Resmi Bedeli*",
      //             description: "1.836 TL",
      //           },
      //           {
      //             title: "TTİ Hizmet Bedeli*",
      //             description: "2.300 TL",
      //           },
      //         ]}
      //         kafone={"3.450.000 TL"}
      //         carName={"Multivan"}
      //         modelNameOne={
      //           "Yeni Multivan Style 1.4 TSI eHybrid (6+1) Uzun Şasi 218 PS DSG"
      //         }
      //         containerColor={"#91969d"}
      //         isStrike={true}
      //         carImage={24}
      //       />
      //       <br />
      //       <OptionalAccordion
      //         optional={[
      //           {
      //             title: "Metalik Renk",
      //             description: "75.000 TL",
      //           },
      //           {
      //             title: "Çift Renk",
      //             description: "150.000 TL",
      //           },
      //         ]}
      //         isOpen={true}
      //         icon={false}
      //       />
      //       {this.renderMultiVanText()}
      //     </Container>
      //   );
      // }
      else if (selectedIndex === 3 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.crafterServis16ModelsDetails}
              dataTwo={oldCaddyModels.crafterServis19ModelsDetails}
              kafone={this.state.crafterServisELWB16.campaignPrice}
              kaftwo={this.state.crafterServisELWB19.campaignPrice}
              carName={"Crafter Servis"}
              modelNameOne={
                "Crafter Servis 2.0 TDI (16+1) ELWB HR 163 PS Comfortline 4.9T"
              }
              modelNameTwo={
                "Crafter Servis 2.0 TDI (19+1) ELWB HR 163 PS Comfortline 4.9T"
              }
              containerColor={"#91969d"}
              carImage={13}
            />
            <br />
            <PriceCard data={oldCaddyModels.crafterOkul16ModelsDetails}
              dataTwo={oldCaddyModels.crafterOkul19ModelsDetails}
              containerColor={'#dedede'}
              kafone={this.state.crafterOkulELWB16.campaignPrice}
              kaftwo={this.state.crafterOkulELWB19.campaignPrice}
              isSpecialFooter={false}
              carName={'Crafter Okul'}
              modelNameOne={'Crafter Okul 2.0 TDI (16+1) ELWB HR 163 PS Comfortline 4.9T'}
              modelNameTwo={'Crafter Okul 2.0 TDI (19+1) ELWB HR 163 PS Comfortline 4.9T'}
              carImage={14} /><br />

            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.crafterPanelVanMWBModelsDetails}
              dataTwo={oldCaddyModels.crafterPanelVanLWBModelsDetails}
              dataThree={oldCaddyModels.crafterPanelVanLWB177ModelsDetails}
              modelNameOne={
                "Crafter Panel Van 2.0 TDI (2+1) MWB HR 140 PS 3,5 T Comfortline"
              }
              modelNameTwo={
                "Crafter Panel Van 2.0 TDI (2+1) LWB HR 140 PS 3,5 T Comfortline"
              }
              modelNameThree={
                "Crafter Panel Van 2.0 TDI (2+1) LWB HR 177 PS 3,5 T Comfortline"
              }
              containerColor={"#bebebe"}
              kafone={this.state.crafterPanelVanMWB.campaignPrice}
              kaftwo={this.state.crafterPanelVanLWB.campaignPrice}
              kafthree={this.state.crafterPanelVanLWB177.campaignPrice}
              carName={"Crafter Panel Van"}
              carImage={15}
            />
            <br />
            <OptionalAccordion
              optional={oldCrafterOptional.crafterOptionalDetails}
              isOpen={true}
              icon={false}
            />
            {this.renderCrafterTexts(0)}
          </Container>
          // dataThree={caddyModels.crafterServis15ModelsDetails} kafthree={this.state.crafterServisLWB15.campaignPrice}   modelNameThree={'Crafter Servis 2.0 TDI (15+1) LWB HR 140 PS Comfortline'}
        );
      }
      else if (selectedIndex === 4 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.amarokAventuraTDI240}
              modelNameOne={
                "Amarok V6 3.0 TDI 240 PS Aventura Oto. 4Motion"
              }
              containerColor={"#2888c4"}
              kafone={this.state.amarokAventuraTDI240.campaignPrice}
              carName={"Amarok Aventura"}
              carImage={19}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.amarokPanAmericanoTDI240}
              modelNameOne={
                "Amarok V6 3.0 TDI 240 PS PanAmericana Oto. 4Motion"
              }
              containerColor={"#a69c8f"}
              kafone={this.state.amarokPanAmericanoTDI240.campaignPrice}
              carName={"Amarok PanAmericana"}
              carImage={20}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.amarokStylePlusTDI205}
              modelNameOne={
                "Amarok 2.0 TDI 205 PS Style Plus Oto. 4Motion"
              }
              containerColor={"#bcbebd"}
              kafone={this.state.amarokStylePlusTDI205.campaignPrice}
              carName={"Amarok Style Plus"}
              carImage={21}
            />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.amarokStyleTDI205}
              dataTwo={oldCaddyModels.amarokStyleTDI240}
              modelNameOne={
                "Amarok 2.0 TDI 205 PS Style Oto. 4Motion"
              }
              modelNameTwo={
                "Amarok V6 3.0 TDI 240 PS Style Oto. 4Motion"
              }
              containerColor={"#a82a2d"}
              kafone={this.state.amarokStyleTDI205.campaignPrice}
              kaftwo={this.state.amarokStyleTDI240.campaignPrice}
              carName={"Amarok Style"}
              carImage={22}
            />
            <br />
            {/* <PriceCard
              isSpecialFooter={false}
              data={oldCaddyModels.amarokLifeTDI205}
              modelNameOne={
                "Amarok 2.0 TDI 205 PS Life Oto. 4Motion"
              }
              containerColor={"#848688"}
              kafone={this.state.amarokLifeTDI205.campaignPrice}
              carName={"Amarok Life"}
              carImage={23}
            /> */}
            <br />
            {this.renderAmarokText()}
          </Container>
        );
      }
      else if (selectedIndex === 5 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            <br />

            <PriceCard
              isSpecialFooter={false}
              // data={[
              //   {
              //     title: "Anahtar Teslim Fiyatı",
              //     description: "3.568.000 TL",
              //   },
              //   // {
              //   //   title: "Kampanyalı Anahtar Teslim Fiyatı",
              //   //   description: this.state.californiaOcean20TDI.campaignPrice,
              //   // },
              //   {
              //     title: "Net Fiyat",
              //     description: "2.046.374 TL",
              //   },
              //   {
              //     title: "ÖTV",
              //     description: "920.868 TL",
              //   },
              //   {
              //     title: "KDV",
              //     description: "593.448 TL",
              //   },
              //   {
              //     title: "MTV",
              //     description: "4.053 TL",
              //   },
              //   {
              //     title: "TTİ Resmi Bedeli*",
              //     description: "1.836 TL",
              //   },
              //   {
              //     title: "TTİ Hizmet Bedeli*",
              //     description: "1.420 TL",
              //   },
              // ]}
              data={[
                {
                  title: "Anahtar Teslim Fiyatı",
                  description: "3.675.000 TL",
                },
                // {
                //   title: "Kampanyalı Anahtar Teslim Fiyatı",
                //   description: this.state.californiaOcean20TDI4Motion.campaignPrice,
                // },
                {
                  title: "Net Fiyat",
                  description: "2.107.868 TL",
                },
                {
                  title: "ÖTV",
                  description: "948.541 TL",
                },
                {
                  title: "KDV",
                  description: "611.282 TL",
                },
                {
                  title: "MTV",
                  description: "4.053 TL",
                },
                {
                  title: "TTİ Resmi Bedeli*",
                  description: "1.836 TL",
                },
                {
                  title: "TTİ Hizmet Bedeli*",
                  description: "1.420 TL",
                },
              ]}
              // modelNameOne={
              //   "California Ocean 2.0 TDI 204 PS DSG"
              // }
              modelNameOne={
                "California Ocean 2.0 TDI 204 PS DSG 4Motion"
              }
              containerColor={"#bebebe"}
              kafone={this.state.californiaOcean20TDI4Motion.campaignPrice}
              // kaftwo={this.state.californiaOcean20TDI4Motion.campaignPrice}
              carName={"California Ocean"}
              carImage={16}
            />
            <br />
            <OptionalAccordion
              optional={[
                {
                  title: "Metalik Renk",
                  description: this.state.opsiyonelCaliforniaOceanOne,
                },
                {
                  title: "Çift Renk*",
                  description: this.state.opsiyonelCaliforniaOceanTwo,
                },

              ]}
              isOpen={true}
              icon={false}
            />
            {this.renderCaliforniaText()}
          </Container>
        );
      }
      else if (selectedIndex === 6 && isMobile) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
            padding={{ top: "10px" }}
          >
            <br />
            <br />
            <br />
            <br />
            <PriceCard
              isSpecialFooter={false}
              data={[
                {
                  title: "Anahtar Teslim Fiyatı",
                  description: "3.750.000 TL",
                },
                // {
                //   title: "Kampanyalı Anahtar Teslim Fiyatı",
                //   description: this.state.grandCalifornia6004Motion.campaignPrice,
                // },
                {
                  title: "Net Fiyat",
                  description: "2.150.466 TL",
                },
                {
                  title: "ÖTV",
                  description: "967.710 TL",
                },
                {
                  title: "KDV",
                  description: "623.635 TL",
                },
                {
                  title: "MTV",
                  description: "4.053 TL",
                },
                {
                  title: "TTİ Resmi Bedeli*",
                  description: "1.836 TL",
                },
                {
                  title: "TTİ Hizmet Bedeli*",
                  description: "2.300 TL",
                },
              ]}
              modelNameOne={
                "Grand California 600 2.0 TDI 177 PS Oto. 4Motion"
              }
              containerColor={"#bebebe"}
              kafone={this.state.grandCalifornia6004Motion.campaignPrice}
              carName={"Grand California"}
              carImage={18}
            />
            <br />
            <OptionalAccordion
              optional={[
                {
                  title: "Metalik Renk",
                  description: "55.000 TL",
                },
                {
                  title: "Çift Renk*",
                  description: "120.000 TL",
                },
              ]}
              isOpen={true}
              icon={false}
            />
            {this.renderGrandCaliforniaText()}
          </Container>
        );
      }
    }
  }

  renderDesktopViews(
    selectedIndex,
    selectedYearIndex,
    caddyModels,
    oldCaddyModels,
    caddyOptional,
    oldCaddyOptional,
    transporterOptional,
    oldTransporterOptional,
    caravelleOptional,
    crafterOptional,
    oldCrafterOptional,
  ) {
    const {
      custom,
      custom1,
      custom2,
      custom3,
      custom4,
      custom5,
      custom6,
      custom7,
      custom8,
      custom9,
      custom10,
      custom11,
      custom12,
      custom13,
      custom14,
      custom15,
      custom16,
      custom18,
      custom19,
      custom20,
      custom21,
      custom22,
      custom23,
      custom24,
      custom25,
    } = this.props;
    if (selectedYearIndex == 1) {

      if (selectedIndex === 0) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={oldCaddyModels.caddyStyleDSGDetails}
              carName={"Caddy Style"}
              carImage={1}
              carSource={custom}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              // model={oldCaddyModels.caddyLifeDetails}
              model={oldCaddyModels.caddyLifeDSGDetails}
              isManuelOut={true}
              carName={"Caddy Life"}
              carImage={2}
              carSource={custom1}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              // model={oldCaddyModels.caddyImpressionDetails}
              model={oldCaddyModels.caddyImptessionDSGDetails}
              carName={"Caddy Impression"}
              isManuelOut={true}
              carImage={3}
              carSource={custom2}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              // model={oldCaddyModels.caddyCargo122PSDetails}
              model={oldCaddyModels.caddyCargo122PSDSGDetails}
              // modelThree={oldCaddyModels.caddyCargoMaxi122PSDetails}
              modelTwo={oldCaddyModels.caddyCargoMaxi122PSDSGDetails}
              isManuelOut={true}
              carName={"Caddy Cargo"}
              carImage={4}
              carSource={custom3}
            />
            <br />
            <br />
            <OptionalAccordion
              optional={oldCaddyOptional.caddyOptionalDetails}
              isOpen={true}
            />
            {this.renderCaddyNotesTexts()}
          </Container>
        );
      } else if (selectedIndex === 1) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={oldCaddyModels.trCityVanStandartSasiModelsDetails}
              modelTwo={oldCaddyModels.trCityVanUzunSasiModelsDetails}
              carName={"Transporter Kombi"}
              carImage={5}
              carSource={custom5}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={oldCaddyModels.trPanelVanStandartSasiModelsDetails}
              modelTwo={oldCaddyModels.trPanelVanUzunSasiModelsDetails}
              modelThree={oldCaddyModels.trPanelVanUzunSasi150ModelsDetails}
              carName={"Transporter Panel Van"}
              carImage={6}
              carSource={custom6}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={oldCaddyModels.trPikapTekKabinModelsDetails}
              modelTwo={oldCaddyModels.trPikapCiftKabinModelsDetails}
              carName={"Transporter Pikap"}
              carImage={7}
              carSource={custom7}
            />
            <br />
            <br />
            <OptionalAccordion
              optional={transporterOptional.transporterOptionalDetails}
              isOpen={true}
            />
            {this.renderTransporterTexts(0)}
          </Container>
        );
      } else if (selectedIndex === 2) {

        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={oldCaddyModels.caraHighlineUzunSasi199DSGModelsDetails}
              modelTwo={
                oldCaddyModels.caraHighlineUzunSasi199DSG4MotionModelsDetails
              }
              carName={"Caravelle Highline"}
              carImage={9}
              carSource={custom9}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              // model={oldCaddyModels.caraComfortLineUzunSasi150ModelsDetails}
              modelTwo={oldCaddyModels.caraComfortLineUzunSasi150DSGModelsDetails}
              // modelThree={oldCaddyModels.caraComfortLineUzunSasi110ModelsDetails}
              carName={"Caravelle Comfortline"}
              carImage={8}
              carSource={custom10}
            />
            <br />
            <br />
            {/* <OptionalAccordion
              optional={caravelleOptional.caravelleOptionalDetails}
              isOpen={true}
            /> */}
            {this.renderCaravelleTexts()}
          </Container>
        );
      }
      // else if (selectedIndex === 3) {
      //   return (
      //     <Container
      //       horizontalAlign={ContainerHorizontalAlignment.center}
      //       wrap={ContainerWrap.always}
      //     >
      //       <br />
      //       <br />
      //       <br />
      //       <br />
      //       <PriceTable
      //         selectedIndex={selectedIndex}
      //         isSpecialFooter={false}
      //         model={[
      //           {
      //             title: "Anahtar Teslim Fiyatı",
      //             description: this.state.multiVanTSI218.turnkeyPrice,
      //           },
      //           // {
      //           //   title: "Kampanyalı Anahtar Teslim Fiyatı",
      //           //   description: this.state.crafterOkulELWB16.campaignPrice,
      //           // },
      //           {
      //             title: "Net Fiyat",
      //             description: this.state.multiVanTSI218.netPrice,
      //           },
      //           {
      //             title: "ÖTV",
      //             description: this.state.multiVanTSI218.otv,
      //           },
      //           {
      //             title: "KDV",
      //             description: this.state.multiVanTSI218.kdv,
      //           },
      //           {
      //             title: "MTV",
      //             description: this.state.multiVanTSI218.mtv,
      //           },
      //           {
      //             title: "TTİ Resmi Bedeli*",
      //             description: this.state.multiVanTSI218.ttiResmi,
      //           },
      //           {
      //             title: "TTİ Hizmet Bedeli*",
      //             description: this.state.multiVanTSI218.ttiHizmet,
      //           },
      //         ]}
      //         carName={"Multivan"}
      //         carImage={15}
      //         carSource={custom25} />
      //       <br />
      //       <br />
      //       <br />
      //       <OptionalAccordion
      //         optional={[
      //           {
      //             title: "Metalik Renk",
      //             description: this.state.opsiyonelMultiVanOne,
      //           },
      //           {
      //             title: "Çift Renk",
      //             description: this.state.opsiyonelMultiVanTwo,
      //           },
      //         ]}
      //         isOpen={true}
      //       />
      //       {this.renderMultiVanText()}
      //     </Container>
      //   );
      // }
      else if (selectedIndex === 3) {

        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={oldCaddyModels.crafterServis16ModelsDetails}
              modelTwo={oldCaddyModels.crafterServis19ModelsDetails}
              carName={"Crafter Servis"}
              carImage={13}
              carSource={custom12}
            />
            <br />
            <br />

            <PriceTable
              selectedIndex={selectedIndex}
              isSpecialFooter={false}
              model={oldCaddyModels.crafterOkul16ModelsDetails}
              modelTwo={oldCaddyModels.crafterOkul19ModelsDetails}
              carName={"Crafter Okul"}
              carImage={14}
              carSource={custom13} />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={oldCaddyModels.crafterPanelVanMWBModelsDetails}
              modelTwo={oldCaddyModels.crafterPanelVanLWBModelsDetails}
              modelThree={oldCaddyModels.crafterPanelVanLWB177ModelsDetails}
              carName={"Crafter Panel Van"}
              carImage={15}
              carSource={custom14}
            />

            <br />
            <br />
            <OptionalAccordion
              optional={crafterOptional.crafterOptionalDetails}
              isOpen={true}
            />
            {this.renderCrafterTexts(0)}
          </Container>
        );
      }
      else if (selectedIndex === 4) {
        return (
          // <>
          // <Container
          //   padding={padding4Dimensions}
          //   horizontalAlign={ContainerHorizontalAlignment.center}
          // >
          //   <h2>
          //     <Text
          //       textAlign={TextAlignment.center}
          //       appearance={TextAppearance.headline0400}
          //     >
          //       Fiyatlarımızı&nbsp;
          //       <br />
          //       <Text bold appearance={TextAppearance.headline0400}>
          //         güncelliyoruz
          //       </Text>
          //     </Text>
          //   </h2>
          // </Container>
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={oldCaddyModels.amarokAventuraTDI240}
              carName={"Amarok Aventura"}
              carImage={15}
              carSource={custom20}
              oldCarPrice={1} />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={oldCaddyModels.amarokPanAmericanoTDI240}
              carName={"Amarok PanAmericana"}
              carImage={15}
              carSource={custom21}
              oldCarPrice={1} />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={oldCaddyModels.amarokStylePlusTDI205}
              carName={"Amarok Style Plus"}
              carImage={15}
              carSource={custom22}
              oldCarPrice={1} />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={oldCaddyModels.amarokStyleTDI205}
              modelTwo={oldCaddyModels.amarokStyleTDI240}
              carName={"Amarok Style"}
              carImage={15}
              carSource={custom23} />
            <br />
            <br />
            {/* <PriceTable
                isSpecialFooter={false}
                selectedIndex={selectedIndex}
                model={oldCaddyModels.amarokLifeTDI205}
                carName={"Amarok Life"}
                carImage={15}
                carSource={custom24} /> */}
            <br />
            <br />
            {this.renderAmarokText()}
          </Container>
        );
      }
      else if (selectedIndex === 5) {
        return (

          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={true}
              selectedIndex={selectedIndex}
              // model={[
              //   {
              //     title: "Anahtar Teslim Fiyatı",
              //     description: this.state.californiaOcean20TDI.turnkeyPrice,
              //   },
              //   // {
              //   //   title: "Kampanyalı Anahtar Teslim Fiyatı",
              //   //   description: this.state.californiaOcean20TDI.campaignPrice,
              //   // },
              //   {
              //     title: "Net Fiyat",
              //     description: this.state.californiaOcean20TDI.netPrice,
              //   },
              //   {
              //     title: "ÖTV",
              //     description: this.state.californiaOcean20TDI.otv,
              //   },
              //   {
              //     title: "KDV",
              //     description: this.state.californiaOcean20TDI.kdv,
              //   },
              //   {
              //     title: "MTV",
              //     description: this.state.californiaOcean20TDI.mtv,
              //   },
              //   {
              //     title: "TTİ Resmi Bedeli*",
              //     description: this.state.californiaOcean20TDI.ttiResmi,
              //   },
              //   {
              //     title: "TTİ Hizmet Bedeli*",
              //     description: this.state.californiaOcean20TDI.ttiHizmet,
              //   },
              // ]}
              model={[
                {
                  title: "Anahtar Teslim Fiyatı",
                  description: this.state.californiaOcean20TDI4Motion.turnkeyPrice,
                },
                {
                  title: "Kampanyalı Anahtar Teslim Fiyatı",
                  description: this.state.californiaOcean20TDI4Motion.campaignPrice,
                },
                {
                  title: "Net Fiyat",
                  description: this.state.californiaOcean20TDI4Motion.netPrice,
                },
                {
                  title: "ÖTV",
                  description: this.state.californiaOcean20TDI4Motion.otv,
                },
                {
                  title: "KDV",
                  description: this.state.californiaOcean20TDI4Motion.kdv,
                },
                {
                  title: "MTV",
                  description: this.state.californiaOcean20TDI4Motion.mtv,
                },
                {
                  title: "TTİ Resmi Bedeli*",
                  description: this.state.californiaOcean20TDI4Motion.ttiResmi,
                },
                {
                  title: "TTİ Hizmet Bedeli*",
                  description: this.state.californiaOcean20TDI4Motion.ttiHizmet,
                },
              ]}
              carName={"California Ocean"}
              carImage={16}
              isStrike={true}
              carSource={custom16}
            // oldCarPrice={1}
            />
            <br />
            <br />
            <OptionalAccordion
              optional={[
                {
                  title: "Metalik Renk",
                  description: this.state.opsiyonelCaliforniaOceanOne,
                },
                {
                  title: "Çift Renk*",
                  description: this.state.opsiyonelCaliforniaOceanTwo,
                },

              ]}
              isOpen={true}
            />
            {this.renderCaliforniaText()}
          </Container>
        );
      }

      else if (selectedIndex === 6) {




        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={true}
              selectedIndex={selectedIndex}
              model={[
                {
                  title: "Anahtar Teslim Fiyatı",
                  description: this.state.grandCalifornia6004Motion.turnkeyPrice,
                },
                {
                  title: "Kampanyalı Anahtar Teslim Fiyatı",
                  description: this.state.grandCalifornia6004Motion.campaignPrice,
                },
                {
                  title: "Net Fiyat",
                  description: this.state.grandCalifornia6004Motion.netPrice,
                },
                {
                  title: "ÖTV",
                  description: this.state.grandCalifornia6004Motion.otv,
                },
                {
                  title: "KDV",
                  description: this.state.grandCalifornia6004Motion.kdv,
                },
                {
                  title: "MTV",
                  description: this.state.grandCalifornia6004Motion.mtv,
                },
                {
                  title: "TTİ Resmi Bedeli*",
                  description: this.state.grandCalifornia6004Motion.ttiResmi,
                },
                {
                  title: "TTİ Hizmet Bedeli*",
                  description: this.state.grandCalifornia6004Motion.ttiHizmet,
                },
              ]}
              carName={"Grand California"}
              isStrike={true}
              carImage={18}
              carSource={custom18}
            // oldCarPrice={1}
            />
            <br />
            <br />
            <OptionalAccordion
              optional={[
                {
                  title: "Metalik Renk",
                  description: this.state.opsiyonelGrandCaliforniaOne,
                },
                {
                  title: "Çift Renk*",
                  description: this.state.opsiyonelGrandCaliforniaTwo,
                },
              ]}
              isOpen={true}
            />
            {this.renderGrandCaliforniaText()}
          </Container>



        );
      }

    } else if (selectedYearIndex == 0) {

      if (selectedIndex === 0) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.caddyStyleDSGDetails}
              carName={"Caddy Style"}
              carImage={1}
              carSource={custom}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.caddyLifeDetails}
              modelTwo={caddyModels.caddyLifeDSGDetails}
              carName={"Caddy Life"}
              carImage={2}
              carSource={custom1}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.caddyImpressionDetails}
              modelTwo={caddyModels.caddyImptessionDSGDetails}
              carName={"Caddy Impression"}
              carImage={3}
              carSource={custom2}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.caddyCargo122PSDetails}
              modelTwo={caddyModels.caddyCargo122PSDSGDetails}
              modelThree={caddyModels.caddyCargoMaxi122PSDetails}
              modelFour={caddyModels.caddyCargoMaxi122PSDSGDetails}
              carName={"Caddy Cargo"}
              carImage={4}
              carSource={custom3}
            />
            <br />
            <br />
            <OptionalAccordion
              optional={caddyOptional.caddyOptionalDetails}
              isOpen={true}
            />
            {this.renderCaddyNotesTexts()}
          </Container>
        );
      } else if (selectedIndex === 1) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.trCityVanStandartSasiModelsDetails}
              modelTwo={caddyModels.trCityVanUzunSasiModelsDetails}
              carName={"Transporter Kombi"}
              carImage={5}
              carSource={custom5}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.trPanelVanStandartSasiModelsDetails}
              modelTwo={caddyModels.trPanelVanUzunSasiModelsDetails}
              modelThree={caddyModels.trPanelVanUzunSasi150ModelsDetails}
              carName={"Transporter Panel Van"}
              carImage={6}
              carSource={custom6}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.trPikapTekKabinModelsDetails}
              modelTwo={caddyModels.trPikapCiftKabinModelsDetails}
              carName={"Transporter Pikap"}
              carImage={7}
              carSource={custom7}
            />
            <br />
            <br />
            <OptionalAccordion
              optional={oldTransporterOptional.transporterOptionalDetails}
              isOpen={true}
            />
            {this.renderTransporterTexts(1)}
          </Container>
        );
      } else if (selectedIndex === 2) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.caraHighlineUzunSasi199DSGModelsDetails}
              modelTwo={
                caddyModels.caraHighlineUzunSasi199DSG4MotionModelsDetails
              }
              carName={"Caravelle Highline"}
              carImage={9}
              carSource={custom9}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.caraComfortLineUzunSasi150ModelsDetails}
              modelTwo={caddyModels.caraComfortLineUzunSasi150DSGModelsDetails}
              modelThree={caddyModels.caraComfortLineUzunSasi110ModelsDetails}
              carName={"Caravelle Comfortline"}
              carImage={8}
              carSource={custom10}
            />
            <br />
            <br />
            {/* <OptionalAccordion
              optional={caravelleOptional.caravelleOptionalDetails}
              isOpen={true}
            /> */}
            {this.renderCaravelleTexts()}
          </Container>
        );
      }
      // else if (selectedIndex === 3) {
      //   return (
      //     <Container
      //       horizontalAlign={ContainerHorizontalAlignment.center}
      //       wrap={ContainerWrap.always}
      //     >
      //       <br />
      //       <br />
      //       <br />
      //       <br />
      //       <PriceTable
      //         selectedIndex={selectedIndex}
      //         isSpecialFooter={true}
      //         model={[
      //           {
      //             title: "Anahtar Teslim Fiyatı",
      //             description: "4.100.000 TL",
      //           },
      //           {
      //             title: "Kampanyalı Anahtar Teslim Fiyatı",
      //             description: "3.450.000 TL",
      //           },
      //           {
      //             title: "Net Fiyat",
      //             description: "1.593.681 TL",
      //           },
      //           {
      //             title: "ÖTV",
      //             description: "1.274.945 TL",
      //           },
      //           {
      //             title: "KDV",
      //             description: "573.725 TL",
      //           },
      //           {
      //             title: "MTV",
      //             description: "3.513 TL",
      //           },
      //           {
      //             title: "TTİ Resmi Bedeli*",
      //             description: "1.836 TL",
      //           },
      //           {
      //             title: "TTİ Hizmet Bedeli*",
      //             description: "2.300 TL",
      //           },
      //         ]}
      //         carName={"Multivan"}
      //         carImage={15}
      //         isStrike={true}
      //         carSource={custom25} />
      //       <br />
      //       <br />
      //       <br />
      //       <OptionalAccordion
      //         optional={[
      //           {
      //             title: "Metalik Renk",
      //             description: "75.000 TL",
      //           },
      //           {
      //             title: "Çift Renk",
      //             description: "150.000 TL",
      //           },
      //         ]}
      //         isOpen={true}
      //       />
      //       {this.renderMultiVanText()}
      //     </Container>
      //   );
      // }
      else if (selectedIndex === 3) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            {/* {this.renderPastPriceListContainer()} */}
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.crafterServis16ModelsDetails}
              modelTwo={caddyModels.crafterServis19ModelsDetails}
              carName={"Crafter Servis"}
              carImage={13}
              carSource={custom12}
            />
            <br />
            <br />

            <PriceTable
              selectedIndex={selectedIndex}
              isSpecialFooter={false}
              model={[
                {
                  title: "Anahtar Teslim Fiyatı",
                  description: "2.134.000 TL",
                },
                // {
                //   title: "Kampanyalı Anahtar Teslim Fiyatı",
                //   description: this.state.crafterOkulELWB16.campaignPrice,
                // },
                {
                  title: "Net Fiyat",
                  description: "1.705.012 TL",
                },
                {
                  title: "ÖTV",
                  description: "68.200 TL",
                },
                {
                  title: "KDV",
                  description: "354.643 TL",
                },
                {
                  title: "MTV",
                  description: "2.008 TL",
                },
                {
                  title: "TTİ Resmi Bedeli*",
                  description: "1.836 TL",
                },
                {
                  title: "TTİ Hizmet Bedeli*",
                  description: "2.300 TL",
                },
              ]}
              modelTwo={[
                {
                  title: "Anahtar Teslim Fiyatı",
                  description: "2.277.000 TL",
                },
                // {
                //   title: "Kampanyalı Anahtar Teslim Fiyatı",
                //   description: this.state.crafterOkulELWB19.campaignPrice,
                // },
                {
                  title: "Net Fiyat",
                  description: "1.817.140 TL",
                },
                {
                  title: "ÖTV",
                  description: "72.686 TL",
                },
                {
                  title: "KDV",
                  description: "377.965 TL",
                },
                {
                  title: "MTV",
                  description: "5.073 TL",
                },
                {
                  title: "TTİ Resmi Bedeli*",
                  description: "1.836 TL",
                },
                {
                  title: "TTİ Hizmet Bedeli*",
                  description: "2.300 TL",
                },
              ]}

              carName={"Crafter Okul"}
              carImage={14}
              carSource={custom13} />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.crafterPanelVanMWBModelsDetails}
              modelTwo={caddyModels.crafterPanelVanLWBModelsDetails}
              modelThree={caddyModels.crafterPanelVanLWB177ModelsDetails}
              carName={"Crafter Panel Van"}
              carImage={15}
              carSource={custom14}
              oldCarPrice={1}
            />
            <br />
            <br />
            <OptionalAccordion
              optional={oldCrafterOptional.crafterOptionalDetails}
              isOpen={true}
            />
            {this.renderCrafterTexts(1)}
          </Container>
        );
      } else if (selectedIndex === 4) {
        return (
          // <>Fiyatlarımız guncellenmektedir</>
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.amarokAventuraTDI240}
              carName={"Amarok Aventura"}
              carImage={15}
              carSource={custom20}
              oldCarPrice={1}
            />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.amarokPanAmericanoTDI240}
              carName={"Amarok PanAmericana"}
              carImage={15}
              carSource={custom21}
              oldCarPrice={1}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.amarokStylePlusTDI205}
              carName={"Amarok Style Plus"}
              carImage={15}
              carSource={custom22}
              oldCarPrice={1}
            />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.amarokStyleTDI205}
              modelTwo={caddyModels.amarokStyleTDI240}
              carName={"Amarok Style"}
              carImage={15}
              carSource={custom23}
              oldCarPrice={1}
            />
            <br />
            <br />
            {/* <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={caddyModels.amarokLifeTDI205}
              carName={"Amarok Life"}
              carImage={15}
              carSource={custom24}
              oldCarPrice={1}
            />
            <br /> */}
            <br />
            {this.renderAmarokText()}
          </Container>
        );
      }
      else if (selectedIndex === 5) {
        return ( /*asdasd*/
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              // model={[
              //   {
              //     title: "Anahtar Teslim Fiyatı",
              //     description: "3.568.000 TL",
              //   },
              //   // {
              //   //   title: "Kampanyalı Anahtar Teslim Fiyatı",
              //   //   description: this.state.californiaOcean20TDI.campaignPrice,
              //   // },
              //   {
              //     title: "Net Fiyat",
              //     description: "2.045.868 TL",
              //   },
              //   {
              //     title: "ÖTV",
              //     description: "920.641 TL",
              //   },
              //   {
              //     title: "KDV",
              //     description: "593.302 TL",
              //   },
              //   {
              //     title: "MTV",
              //     description: "4.053 TL",
              //   },
              //   {
              //     title: "TTİ Resmi Bedeli*",
              //     description: "1.836 TL",
              //   },
              //   {
              //     title: "TTİ Hizmet Bedeli*",
              //     description: "2.300 TL",
              //   },
              // ]}
              model={[
                {
                  title: "Anahtar Teslim Fiyatı",
                  description: "3.675.000 TL",
                },
                // {
                //   title: "Kampanyalı Anahtar Teslim Fiyatı",
                //   description: this.state.californiaOcean20TDI4Motion.campaignPrice,
                // },
                {
                  title: "Net Fiyat",
                  description: "2.107.362 TL",
                },
                {
                  title: "ÖTV",
                  description: "948.313 TL",
                },
                {
                  title: "KDV",
                  description: "611.135 TL",
                },
                {
                  title: "MTV",
                  description: "4.053 TL",
                },
                {
                  title: "TTİ Resmi Bedeli*",
                  description: "1.836 TL",
                },
                {
                  title: "TTİ Hizmet Bedeli*",
                  description: "2.300 TL",
                },
              ]}
              carName={"California Ocean"}
              carImage={15}
              carSource={custom16}
              oldCarPrice={1}
            />
            <br />
            <br />
            <OptionalAccordion
              optional={[
                {
                  title: "Metalik Renk",
                  description: "27.500 TL",
                },
                {
                  title: "Çift Renk*",
                  description: "120.000 TL",
                },

              ]}
              isOpen={true}
            />
            {this.renderCaliforniaText()}
          </Container>
        );
      }
      else if (selectedIndex === 6) {
        return (
          <Container
            horizontalAlign={ContainerHorizontalAlignment.center}
            wrap={ContainerWrap.always}
          >
            <br />
            <br />
            <br />
            <br />
            <br />
            <PriceTable
              isSpecialFooter={false}
              selectedIndex={selectedIndex}
              model={[
                {
                  title: "Anahtar Teslim Fiyatı",
                  description: "3.750.000 TL",
                },
                // {
                //   title: "Kampanyalı Anahtar Teslim Fiyatı",
                //   description: this.state.grandCalifornia6004Motion.campaignPrice,
                // },
                {
                  title: "Net Fiyat",
                  description: "2.150.466 TL",
                },
                {
                  title: "ÖTV",
                  description: "967.710 TL",
                },
                {
                  title: "KDV",
                  description: "623.635 TL",
                },
                {
                  title: "MTV",
                  description: "4.053 TL",
                },
                {
                  title: "TTİ Resmi Bedeli*",
                  description: "1.836 TL",
                },
                {
                  title: "TTİ Hizmet Bedeli*",
                  description: "2.300 TL",
                },
              ]}
              carName={"Grand California"}
              carImage={15}
              carSource={custom18}
            />
            <br />
            <br />
            <OptionalAccordion
              optional={[
                {
                  title: "Metalik Renk",
                  description: "55.000 TL",
                },
                {
                  title: "Çift Renk*",
                  description: "120.000 TL",
                },
              ]}
              isOpen={true}
            />
            {this.renderGrandCaliforniaText()}
          </Container>
        );
      }

    }
  }

  renderPastPriceListContainer() {
    return (
      <Container
        stretchContent={true}
        horizontalAlign={ContainerHorizontalAlignment.flexEnd}
        padding={topContainerPadding}
      >
        <div style={{ width: "100%", textAlign: "end" }}>
          <a
            href="https://fs-ticariarac.vw.com.tr/vwticari_files/2021.pdf"
            target={"_blank"}
            style={{ textDecoration: "none", color: "#001e50" }}
          >
            2021 Model Yılı Fiyatları için Tıklayın
          </a>
        </div>
      </Container>
    );
  }
  renderCaddyNotesTexts(number) {
    if (number == 1) {
      return (
        <Container stretchContent={true} padding={topContainerPadding}>
          <Text color={TextColor.secondary}>
            <br /> <br />
            {this.state.caddyTextResultOne}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultTwo}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultThree}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultFour}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultFive} <br />
            <br />
          </Text>

          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultSix}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultSeven}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultEight}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            Metalik renk seçeneği opsiyonel olup tavsiye edilen net fiyat Panel Van modeller için 8.149 TL,
            Kombi modeller için 7.369 TL’dir. Opsiyonel renk seçeneğinin araca eklenmesi durumunda, bu fiyat listesinin 3 ve 5 no’lu dipnotlarındaki açıklamalar geçerli olacaktır.
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultTen}
            <br />
            <br />
          </Text>
        </Container>
      );
    } else {
      return (
        <Container stretchContent={true} padding={topContainerPadding}>
          <Text color={TextColor.secondary}>
            <br /> <br />
            {this.state.caddyTextResultOne}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultTwo}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultThree}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultFour}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultFive} <br />
            <br />
          </Text>

          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultSix}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultSeven}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultEight}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultNine}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.caddyTextResultTen}
            <br />
            <br />
          </Text>
        </Container>
      );
    }

  }

  renderTransporterTexts(number) {
    if (number == 1) {
      return (
        <Container stretchContent={true} padding={topContainerPadding}>
          <Text color={TextColor.secondary}>
            <br /> <br />
            {this.state.transporterTextOne}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextTwo}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextThree}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextFour}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextFive} <br />
            <br />
          </Text>

          <Text color={TextColor.secondary}>
            {this.state.transporterTextSix}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextSeven}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextEight}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            Metalik renk seçeneği opsiyonel olup tavsiye edilen net fiyatı Panel Van ve Pikap için 10.593 TL, Camlı Van ve City Van için 9.580 TL’dir. Opsiyonel renk seçeneğinin araca eklenmesi durumunda, bu fiyat listesinin 3 ve 5 no’lu dipnotlarındaki açıklamalar geçerli olacaktır.
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextTen}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextEleven}
            <br />
            <br />
          </Text>
        </Container>
      );
    } else {
      return (
        <Container stretchContent={true} padding={topContainerPadding}>
          <Text color={TextColor.secondary}>
            <br /> <br />
            {this.state.transporterTextOne}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextTwo}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextThree}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextFour}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextFive} <br />
            <br />
          </Text>

          <Text color={TextColor.secondary}>
            {this.state.transporterTextSix}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextSeven}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextEight}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextNine}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextTen}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.transporterTextEleven}
            <br />
            <br />
          </Text>
        </Container>
      );
    }

  }

  renderCaravelleTexts() {
    return (
      <Container stretchContent={true} padding={topContainerPadding}>
        <Text color={TextColor.secondary}>
          <br /> <br />
          {this.state.caravelleTextOne}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.caravelleTextTwo}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.caravelleTextThree}
          <br />
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.caravelleTextFour}
          <br />
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.caravelleTextFive} <br />
          <br />
        </Text>

        <Text color={TextColor.secondary}>
          {this.state.caravelleTextSix}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.caravelleTextSeven}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.caravelleTextEight}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.caravelleTextNine}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.caravelleTextTen}
          <br />
          <br />
        </Text>
      </Container>
    );
  }

  renderCrafterTexts(number) {
    if (number == 1) {
      return (
        <Container stretchContent={true} padding={topContainerPadding}>
          <Text color={TextColor.secondary}>
            <br /> <br />
            {this.state.crafterTextOne}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextTwo}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextThree}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextFour}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextFive} <br />
            <br />
          </Text>

          <Text color={TextColor.secondary}>
            {this.state.crafterTextSix}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextSeven}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextEight}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            Metalik renk seçeneği opsiyonel olup tavsiye edilen net fiyatı 22.001 TL’dir.
            Opsiyonel renk seçeneğinin araca eklenmesi durumunda, bu fiyat listesinin 3 ve 5 no’lu dipnotlarındaki açıklamalar geçerli olacaktır.
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextTen}
            <br />
            <br />
          </Text>
        </Container>
      );
    } else {
      return (
        <Container stretchContent={true} padding={topContainerPadding}>
          <Text color={TextColor.secondary}>
            <br /> <br />
            {this.state.crafterTextOne}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextTwo}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextThree}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextFour}
            <br />
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextFive} <br />
            <br />
          </Text>

          <Text color={TextColor.secondary}>
            {this.state.crafterTextSix}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextSeven}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextEight}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextNine}
            <br />
            <br />
          </Text>
          <Text color={TextColor.secondary}>
            {this.state.crafterTextTen}
            <br />
            <br />
          </Text>
        </Container>
      );
    }

  }

  renderMultiVanText() {
    return (
      <Container stretchContent={true} padding={topContainerPadding}>
        <Text color={TextColor.secondary}>
          <br /> <br />
          {this.state.multiVanTextOne}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.multiVanTextTwo}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.multiVanTextThree}
          <br />
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.multiVanTextFour}
          <br />
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.multiVanTextFive} <br />
          <br />
        </Text>

        <Text color={TextColor.secondary}>
          {this.state.multiVanTextSix}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.multiVanTextSeven}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.multiVanTextEight}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.multiVanTextNine}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.multiVanTextTen}
          <br />
          <br />
        </Text>
      </Container>
    )
  }

  renderAmarokText() {
    return (
      <Container stretchContent={true} padding={topContainerPadding}>
        <Text color={TextColor.secondary}>
          <br /> <br />
          {this.state.amarokTextResultOne}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.amarokTextResultTwo}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.amarokTextResultThree}
          <br />
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.amarokTextResultFour}
          <br />
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.amarokTextResultFive} <br />
          <br />
        </Text>

        <Text color={TextColor.secondary}>
          {this.state.amarokTextResultSix}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.amarokTextResultSeven}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.amarokTextResultEight}
          <br />
          <br />
        </Text>
        {/* <Text color={TextColor.secondary}>
          {this.state.amarokTextResultNine}
          <br />
          <br />
        </Text> */}
        {/* <Text color={TextColor.secondary}>
          {this.state.amarokTextResultTen}
          <br />
          <br />
        </Text> */}
      </Container>
    )
  }

  renderGrandCaliforniaText() {
    return (
      <Container stretchContent={true} padding={topContainerPadding}>
        <Text color={TextColor.secondary}>
          <br /> <br />
          {this.state.grandCaliforniaTextOne}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.grandCaliforniaTextTwo}
          <br />
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.grandCaliforniaTextThree}
          <br />
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.grandCaliforniaTextFour} <br />
          <br />
        </Text>

        <Text color={TextColor.secondary}>
          {this.state.grandCaliforniaTextFive}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.grandCaliforniaTextSix}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.grandCaliforniaTextSeven}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.grandCaliforniaTextEight}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.grandCaliforniaTextNine}
          <br />
          <br />
        </Text>
      </Container>
    )
  }

  renderCaliforniaText() {
    return (
      <Container stretchContent={true} padding={topContainerPadding}>
        <Text color={TextColor.secondary}>
          <br /> <br />
          {this.state.californiaTextOne}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.californiaTextTwo}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.californiaTextThree}
          <br />
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.californiaTextFour}
          <br />
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.californiaTextFive} <br />
          <br />
        </Text>

        <Text color={TextColor.secondary}>
          {this.state.californiaTextSix}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.californiaTextSeven}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.californiaTextEight}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.californiaTextNine}
          <br />
          <br />
        </Text>
        <Text color={TextColor.secondary}>
          {this.state.californiaTextTen}
          <br />
          <br />
        </Text>
      </Container>
    )
  }

}

const carContainerPadding = (index, datasetLength) => ({
  left: {
    [Breakpoints.default]:
      index === 0 ? ContainerPadding.grid003 : ContainerPadding.dynamic0040,
    [Breakpoints.b960]: ContainerPadding.dynamic0020,
  },
  right: {
    [Breakpoints.default]:
      index === datasetLength - 1
        ? ContainerPadding.grid003
        : ContainerPadding.dynamic0040,
    [Breakpoints.b960]: ContainerPadding.dynamic0020,
  },
});

const fixedWidthContainerAppearance = {
  [Breakpoints.default]: 4,
  [Breakpoints.b560]: 4,
  [Breakpoints.tablet]: 2,
  [Breakpoints.b1280]: 2,
  [Breakpoints.b1920]: 1,
};

const dummyArray = [
  {
    name: "Caddy",
    img: caddy,
    activeImage: caddyActive,
  },
  {
    name: "Transporter",
    img: transporter,
    activeImage: transporterActive,
  },
  {
    name: "Caravelle",
    img: transporter,
    activeImage: transporterActive,
  },
  // {
  //   name: "Multivan",
  //   img: multiVan,
  //   activeImage: multiVanActive,
  // },
  {
    name: "Crafter",
    img: crafter,
    activeImage: crafterActive,
  },
  {
    name: "Amarok",
    img: amarok,
    activeImage: amarokActive,
  },
  {
    name: "California",
    img: california,
    activeImage: californiaActive,
  },
  {
    name: "Grand California",
    img: grandCalifornia,
    activeImage: grandCaliforniaActive,
  }
];

const topContainerPadding = {
  left: {
    [Breakpoints.default]: ContainerPadding.grid002,
    [Breakpoints.b2560]: ContainerPadding.grid002,
  },
  right: {
    [Breakpoints.default]: ContainerPadding.grid002,
    [Breakpoints.b2560]: ContainerPadding.grid002,
  },
};

const globalPadding = {
  [Breakpoints.b1920]: ContainerPadding.grid007,
  [Breakpoints.b1600]: ContainerPadding.grid006,
  [Breakpoints.b1280]: ContainerPadding.grid005,
  [Breakpoints.b960]: ContainerPadding.grid004,
  [Breakpoints.default]: ContainerPadding.grid002,
};

const padding4Dimensions = {
  left: globalPadding,
  right: globalPadding,
  bottom: ContainerPadding.dynamic0100,
};


class Tabs extends React.Component {
  state = {
    activeTab: this.props.children[0].props.label
  }
  changeTab = (tab) => {
    this.setState({ activeTab: tab });
  };
  render() {

    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, child => {
          buttons.push(child.props.label)
          if (child.props.label === this.state.activeTab) content = child.props.children
        })}
        <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab} />
        <div className="tab-content">{content}</div>
      </div>
    );
  }
}


class AmarokTabs extends React.Component {
  state = {
    activeTab: "2024 Yılı"
  }
  changeTab = (tab) => {
    this.setState({ activeTab: "2024 Yılı" });
  };
  render() {

    let content;
    let buttons = [];
    return (
      <div>
        {React.Children.map(this.props.children, child => {
          buttons.push(child.props.label)
          if (child.props.label === this.state.activeTab) content = child.props.children
        })}
        <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab} />
        <div className="tab-content">{content}</div>
      </div>
    );
  }
}

const TabButtons = ({ buttons, changeTab, activeTab }) => {

  return (
    <div className="tab-buttons">
      {buttons.map(button => {
        return <button className={"buttonss" + (button === activeTab ? ' actives' : '')} onClick={() => changeTab(button)}>{button}</button>
      })}
    </div>
  )
}

const Tab = props => {
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}



export default HorizontalCarList;
